/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */

enum PermissionEnum {
    ManagementRolesPermissions = "ManagementRolesPermissions",
    ManagementCultivarPermission = "ManagementCultivarPermission",
    ManagementLanguagePermission = "ManagementLanguagePermission",
    ManagementProductsPermission = "ManagementProductsPermission",
    ManagementFarmsPermission = "ManagementFarmsPermission",
    ManagementClientsPermission = "ManagementClientsPermission",
    ManagementUsersPermission = "ManagementUsersPermission",
    ManagementCategoryPermission = "ManagementCategoryPermission",
    ManagementProgramsPermission = "ManagementProgramsPermission",
    ManagementApprovalsPermission = "ManagementApprovalsPermission",
    ManagementTemplatesPermission = "ManagementTemplatesPermission",
    ManagementCropPermission = "ManagementCropPermission",
    ManagementAgentPermission = "ManagementAgentPermission",
    ManagementDistributorPermission = "ManagementDistributorPermission",
    ManagementSyncPermission = "ManagementSyncPermission",
    ManagementCompanyPermission = "ManagementCompanyPermission",
    ManagementAddress = "ManagementAddress",
    ManagementRegions = "ManagementRegions",
    ManageProgramsRemovePermission = "ManageProgramsRemovePermission",
    ManageErrors = "ManageErrors"
}

export default PermissionEnum;
