import MeasureValue from '../../controllers/MeasureValue'
import {Dispatch, Reducer, useReducer} from 'react'
import JsonPlayField from '../../controllers/JsonPlayField'
import ProductLookUpResponse from '../../controllers/ProductLookUpResponse'
import Column from '../../controllers/Column'
import MeasurementUnit from '../../controllers/MeasurementUnit'
import {conversions, convertKgPerHa, ConvertSize, oppositeUnit, summaryUnit} from './units'
import PlayFieldViewBlock from '../../controllers/PlayFieldViewBlock'
import ProductObject from '../../controllers/ProductObject'
import Row from '../../controllers/Row'
import {
    addMeasureValue,
    buildConvertData,
    divMeasureValue,
    emptyPlayField,
    makeProductObject,
    measureValue,
    mulMeasureValue
} from './Helpers'
import ProductUnit from '../../controllers/ProductUnit'
import {arrayPush, arrayRemoveIndex, arrayUpdate, create2dArray, mutate, Writeable} from '../../immutableState'
import {
    cellsToArray,
    CellType,
    columnsToProductLookupResp,
    convertCells,
    emptyCellType,
    emptyConvertSize,
    formatValue, get2dArray,
    getCellSafe,
    getCellValue,
    recalculateCell, set2dArray
} from './Functions'
import { max } from '../../wrapper'
import {inRange} from "../../controllers/helper";
import Cell from '../../controllers/Cell'

export interface ProductReference extends Column {
    product: ProductLookUpResponse;
    key: number;
}

function createOrGetColumns (summary: JsonPlayField, data: ProductReference[]): number[] {
    return data.map<number>(column => {
        // find existing
        const found = summary.columns.find(c => c.productId === column.productId)
        if (!found) {
            // insert new column
            const index = summary.columns.length

            const form = conversions[column.unit].form
            summaryUnit(MeasurementUnit.METRIC, form)

            summary.columns.push({
                index,
                productId: column.productId,
                hexColor: '',
                land: measureValue(0, 0),
                standard: measureValue(0, 0),
                unit: summaryUnit(MeasurementUnit.METRIC, form),
                imperialUnit: summaryUnit(MeasurementUnit.IMPERIAL, form)
            })
            return index
        }
        return found.index
    })
}

function createOrGetRows (summary: JsonPlayField, data: Row[]): number[] {
    return data.map<number>(row => {
        // find existing row
        const found = summary.rows.find(r => r.desc === row.desc && r.month === row.month)
        if (!found) {
            // insert a new row
            const index = summary.rows.length
            summary.rows.push({
                index,
                elements: row.elements,
                month: row.month,
                desc: row.desc
            })
            return index
        }
        // summing existing row elements
        found.elements = makeProductObject(key => addMeasureValue(found.elements[key], row.elements[key]))
        return found.index
    })
}
export interface PlayFieldState {
    change: boolean;
    readonly loaded: boolean;

    readonly data: {
        readonly convertSize: ConvertSize;
        readonly system: MeasurementUnit;

        readonly columns: ProductReference[];
        readonly rows: Row[];
        readonly cells: CellType[][];
    };

    // calculations and extractions
    readonly calculations: {
        readonly standardTotals: MeasureValue[];
        readonly liquidTotals: MeasureValue[];
        readonly landTotals: MeasureValue[];
        readonly extractions: ProductObject<MeasureValue>[];
        readonly elementStdTotals: ProductObject<MeasureValue>;
        readonly elementLandTotals: ProductObject<MeasureValue>;
    }
}

type PlayFieldCalculations = PlayFieldState['calculations'];
type PlayFieldData = PlayFieldState['data'];

function addSummaryLand (summary: JsonPlayField, convertSize: ConvertSize, data: PlayFieldData, products: ProductLookUpResponse[]) {
    const colIndexes = createOrGetColumns(summary, data.columns)
    const rowIndexes = createOrGetRows(summary, data.rows)

    for (let col = 0; col < data.columns.length; col++) {
        for (let row = 0; row < data.rows.length; row++) {
            const cell = get2dArray(data.cells, col, row);
            
            if (!cell || (cell.standard.metric === 0 && cell.standard.imperial === 0)) {
                continue
            }

            // project old cell position to summary cell position.
            const mappedRow = rowIndexes[row]!
            const mappedCol = colIndexes[col]!

            const columnDef = summary.columns[mappedCol]!
            const productDef = productLookup(products, columnDef.productId)
            const form = conversions[columnDef.unit].form

            const convertData = buildConvertData(convertSize, productDef?.sg ?? 1)

            const summaryStandard: MeasureValue = {
                // convert from kgHa to Liter or Kg depending on the form
                metric: convertKgPerHa({
                    value: cell.standard.metric,
                    unit: ProductUnit.KgHa
                }, summaryUnit(MeasurementUnit.METRIC, form), convertData).value,
                // convert from LbAc to Gallon or Lb depending on the form
                imperial: convertKgPerHa({
                    value: cell.standard.imperial,
                    unit: ProductUnit.LbAc
                }, summaryUnit(MeasurementUnit.IMPERIAL, form), convertData).value
            }

            const index = summary.cells.findIndex(c => c.col === mappedCol && c.row === mappedRow)
            if (index === -1) {
                summary.cells.push({
                    row: mappedRow,
                    col: mappedCol,
                    standard: summaryStandard,
                    user: measureValue(0, 0)
                })
            } else {
                // simply add the values
                summary.cells[index] = {
                    user: measureValue(0, 0),
                    standard: addMeasureValue(summaryStandard, summary.cells[index]!.standard),
                    row: mappedRow,
                    col: mappedCol
                }
            }
        }
    }
}

export function productLookup (products: ProductLookUpResponse[], productId: number): ProductLookUpResponse {
    const p = products.find(p => p.id === productId)
    if (p) {
        return p
    }

    throw new Error(`Could not resolve product id ${productId}`)
}

export function toConvertSize (viewBlock: PlayFieldViewBlock): ConvertSize {
    return {
        size: {
            ha: viewBlock.size.metric,
            ac: viewBlock.size.imperial
        },
        water: {
            lHa: viewBlock.water.metric,
            galAc: viewBlock.water.imperial
        },
        trees: viewBlock.totalTrees
    }
}

export interface PlayFieldCalcData {
    data: PlayFieldData;
    viewBlock: PlayFieldViewBlock;
    landId: number;
}

export function calculateSummary (lands: PlayFieldCalcData[], products: ProductLookUpResponse[]): JsonPlayField {
    const ret = emptyPlayField()
    for (const land of lands) {
        addSummaryLand(ret, toConvertSize(land.viewBlock), land.data, products)
    }

    ret.elementTotals = makeProductObject(key => ({
        standard: ret.rows.reduce<MeasureValue>((prev, cur) => addMeasureValue(cur.elements[key], prev), measureValue(0, 0)),
        land: measureValue(0, 0)
    }))

    ret.columns.forEach((column, index) => {
        column.standard = ret.cells
            .filter(c => c.col === index)
            .reduce((prev, cur) => addMeasureValue(cur.standard, prev), measureValue(0, 0))
    })

    return ret
}

// micro keys Fe, Zn, B, Mn, Cu.
// measured in g/Ha, oz/Ac
function isMicroKey (key: keyof ProductObject<any>) {
    return key === 'fe' || key === 'zn' || key === 'b' || key === 'mn' || key === 'cu'
}

// use the product extraction percentage and multiple it with the standard value for each column
function calcExtraction (
    key: keyof ProductObject<{}>,
    columns: ProductReference[],
    getCell: (row: number, column: number) => CellType,
    rowIndex: number,
    productAccess: (product: ProductLookUpResponse) => number): MeasureValue {
    return columns.reduce((prev, current, colIndex) => {
        const standard = getCell(rowIndex, colIndex).standard
        // extraction percentage
        const extraction = productAccess(current.product) / 100

        // micro keys Fe, Zn, B, Mn, Cu. Measured in g/Ha, oz/Ac
        if (isMicroKey(key)) {
            return measureValue(
                prev.metric + (standard.metric * extraction * 1000),
                // conversion lb/ac to ozAz = / 0,0625 or * 16
                prev.imperial + (standard.imperial * extraction * 16)
            )
        }

        // macro keys N,P,K, Ca, Mg, S. Measured in kg/ha, lb/Ac
        // console.log('Product.N', current.product.n);
        return measureValue(
            prev.metric + (standard.metric * extraction),
            // conversion lb/ac to ozAz = / 0,0625 or * 16
            prev.imperial + (standard.imperial * extraction)
        )
    }, measureValue(0, 0))
}

// bridge the gap between a productObject Key "n" to product value p.n
function productValue (key: keyof ProductObject<MeasureValue> & keyof ProductLookUpResponse | 'k10', p: ProductLookUpResponse): number | null {
    if (key === 'k10') {
        return p.k20
    }
    return p[key]
}

function totalsFunc (cells: CellType[][], value: (cell: CellType) => MeasureValue): MeasureValue[] {
    return cells.map(column => column.reduce((prev, row) => addMeasureValue(prev, value(row)), measureValue(0, 0)))
}

export function landTotalsFunc (standardTotals: MeasureValue[], size: MeasureValue) {
    return standardTotals.map(s => mulMeasureValue(s, size))
}

export function extractionFunc (cells: CellType[][], columns: ProductReference[], rows: Row[]): ProductObject<MeasureValue>[] {
    return rows.map<ProductObject<MeasureValue>>((row, rowIndex) =>
        makeProductObject(key => calcExtraction(key, columns, (r, c) => getCellSafe(cells, r, c), rowIndex, p => productValue(key, p) ?? 0)))
}

export function elementStandardTotalsFunc (extractions: ProductObject<MeasureValue>[]): ProductObject<MeasureValue> {
    return extractions.reduce<ProductObject<MeasureValue>>((prev, current) =>
        makeProductObject(key => {
            // sum of micro keys measured in g/Ha
            if (isMicroKey(key)) {
                return addMeasureValue(prev[key], divMeasureValue(current[key], measureValue(1000, 16)))
            }

            return addMeasureValue(prev[key], current[key])
        }), makeProductObject(() => measureValue(0, 0)))
}

export function elementLandTotalsFunc (totals: ProductObject<MeasureValue>, size: MeasureValue) {
    return makeProductObject(key => mulMeasureValue(totals[key], size))
}

interface ColumnData {
    product: ProductLookUpResponse;
    color: string;
    unit: ProductUnit
    system: MeasurementUnit;
}

interface ColumnDataUpdate {
    product?: ProductLookUpResponse;
    color?: string;
    unit?: {
        value: ProductUnit;
        system: MeasurementUnit;
    }
}

interface RowData {
    desc: string;
    month: string
}

// export function updateColumn(index: number, data: Partial<ColumnData>): PlayFieldStateAction {
//     return {type: "updateColumn", index, data}
// }

export type PlayFieldStateAction =
    // column operations
    | { type: 'addColumn' } & ColumnData
    | { type: 'removeColumn', index: number }
    | { type: 'updateColumn', index: number } & ColumnDataUpdate
    | { type: 'replaceColumn', seek: number, replace: ProductLookUpResponse }
    | { type: 'moveColumn', from: number, to: number }

    // row operations
    | { type: 'addRow' } & RowData
    | { type: 'removeRow', index: number }
    | { type: 'updateRow', index: number } & Partial<RowData>

    // left focus of an input. Format value to contain 2 decimal places
    | { type: 'blurCellValue', row: number, column: number }
    // onInput on the cell
    | { type: 'setCellValue', row: number, column: number, value: string }

    | { type: 'set', state: PlayFieldData }
    | { type: 'paste', data: string[][], column: number; row: number }
    | { type: 'saveChanges' }
    | { type: 'recalculateCells', convertSize: ConvertSize }

function resizeCells (cols: number, rows: number, state: CellType[][]): CellType[][] {
    // make whole new 2d array
    const newCells = create2dArray(cols, rows, () => emptyCellType())

    // then copy old values into new array
    for (let c = 0; c < Math.min(newCells.length, state.length); c++) {
        for (let r = 0; r < Math.min(newCells[c]!.length, state[c]!.length); r++) {
            newCells[c]![r] = state[c]![r]!
        }
    }

    return newCells
}

function playFieldCalculations (data: PlayFieldData): PlayFieldCalculations {
    const size = {
        metric: data.convertSize.size.ha,
        imperial: data.convertSize.size.ac
    }
    const standardTotals = totalsFunc(data.cells, c => c.standard)
    const liquidTotals = totalsFunc(data.cells, c => c.liquid)
    const landTotals = landTotalsFunc(standardTotals, size)
    const extractions = extractionFunc(data.cells, data.columns, data.rows)
    const elementStdTotals = elementStandardTotalsFunc(extractions)
    const elementLandTotals = elementLandTotalsFunc(elementStdTotals, size)

    return {
        elementLandTotals,
        liquidTotals,
        elementStdTotals,
        extractions,
        landTotals,
        standardTotals
    }
}

export function getExtraction (state: PlayFieldState, rowIndex: number) {
    return state.calculations.extractions[rowIndex] ?? makeProductObject(() => measureValue(0, 0))
}

export function stateToPlayField (state: PlayFieldState): JsonPlayField {
    return {
        rows: state.data.rows.map((row, index) => ({
            index: row.index,
            desc: row.desc,
            month: row.month,
            elements: getExtraction(state, index)
        })),
        cells: convertCells(state.data.cells),
        columns: state.data.columns.map<Column>((c, index) => ({
            productId: c.productId,
            hexColor: c.hexColor,

            standard: state.calculations.standardTotals[index]!,
            land: state.calculations.landTotals[index]!,

            index: c.index,
            unit: c.unit,
            imperialUnit: c.imperialUnit
        })),
        elementTotals: makeProductObject(k => ({
            land: state.calculations.elementLandTotals[k],
            standard: state.calculations.elementStdTotals[k]
        }))
    }
}

export function buildPlayFieldState (data: JsonPlayField, convertSize: ConvertSize, system: MeasurementUnit, products: ProductLookUpResponse[]): PlayFieldData {
    return {
        cells: cellsToArray(data, system),
        columns: columnsToProductLookupResp(data.columns, p => productLookup(products, p)),
        rows: data.rows,
        system,
        convertSize
    }
}

export function createPlayFieldState (data: JsonPlayField, convertSize: ConvertSize, system: MeasurementUnit, products: ProductLookUpResponse[]): PlayFieldState {
    const build = buildPlayFieldState(data, convertSize, system, products)
    const state: PlayFieldState = {
        data: build,
        loaded: true,
        change: false,
        // playFieldReducer function will calculate
        calculations: emptyPlayFieldCalc
    }
    return playFieldReducer(state, {type: 'recalculateCells', convertSize});
}

const emptyPlayFieldCalc: PlayFieldCalculations = {
    elementLandTotals: makeProductObject(() => measureValue(0, 0)),
    elementStdTotals: makeProductObject(() => measureValue(0, 0)),
    extractions: [],
    liquidTotals: [],
    landTotals: [],
    standardTotals: []
};


export const emptyPlayFieldState: PlayFieldState = {
    change: false,
    loaded: false,
    data: {
        cells: [],
        system: MeasurementUnit.METRIC,
        convertSize: emptyConvertSize,
        columns: [],
        rows: []
    },
    calculations: emptyPlayFieldCalc
}

const waterUnits: ProductUnit[] = [
    ProductUnit.G100Litre,
    ProductUnit.Ml100Litre,
    ProductUnit.L100Litre,
    ProductUnit.Floz100Gallons,
    ProductUnit.Oz100Gallon,
    ProductUnit.Pint100Gallon
]

export function columnsContainsWater (columns: ProductReference[]): boolean {
    return columns.some(c => waterUnits.includes(c.unit) || waterUnits.includes(c.imperialUnit))
}

export function usePlayFieldState (initial: PlayFieldState): [PlayFieldState, Dispatch<PlayFieldStateAction>] {
    return useReducer<Reducer<PlayFieldState, PlayFieldStateAction>>(playFieldReducer, initial)
}

function mutatePlayField (state: PlayFieldState, change: boolean, recalculate: boolean, callback: ((data: Writeable<PlayFieldData>) => void)): PlayFieldState {
    return mutate(state, s => {
        if (change) {
            s.change = true
        }

        s.data = mutate(s.data, callback)

        if (recalculate) {
            s.calculations = playFieldCalculations(s.data)
            
            // s.data.rows = s.data.rows.map((r, i) => ({...r, elements: getExtraction(s, i)}))
            for (const row of s.data.rows) {
                row.elements = getExtraction(s, s.data.rows.indexOf(row));
            }
        }
    })
}

function walk2d<T> (arr: T[][], callback: (r: number, c: number, data: T) => void) {
    for (let r = 0; r < arr.length; r++) {
        for (let c = 0; c < arr[r]!.length; c++) {
            callback(r, c, arr[r]![c]!)
        }
    }
}

function metricImperialUnit(system: MeasurementUnit, current: ProductUnit): [ProductUnit, ProductUnit] {
    if (system == MeasurementUnit.METRIC) 
        return [current, oppositeUnit(current)];

    return [oppositeUnit(current), current];
}

function buildUnit (old: ProductReference, action: { type: 'updateColumn'; index: number } & ColumnDataUpdate): [ProductUnit, ProductUnit] {
    if (!action.unit) return [old.unit, old.imperialUnit]
    
    return metricImperialUnit(action.unit.system, action.unit.value)
}

function cloneMeasureValue (value: MeasureValue): MeasureValue {
    return {
        metric: value.metric,
        imperial: value.imperial
    }
}


export function cloneViewBlock (view: PlayFieldViewBlock, newBlockName: string): PlayFieldViewBlock {
    return {
        size: cloneMeasureValue(view.size),
        water: cloneMeasureValue(view.water),
        rowSpacing: cloneMeasureValue(view.rowSpacing),
        treeSpacing: cloneMeasureValue(view.treeSpacing),
        rowWidth: cloneMeasureValue(view.rowWidth),
        stickWidth: cloneMeasureValue(view.stickWidth),
        totalProduction: cloneMeasureValue(view.totalProduction),
        estimateProduction: cloneMeasureValue(view.estimateProduction),
        withdrawal_N: cloneMeasureValue(view.withdrawal_N),
        withdrawal_P: cloneMeasureValue(view.withdrawal_P),
        withdrawal_P205: cloneMeasureValue(view.withdrawal_P205),
        withdrawal_K: cloneMeasureValue(view.withdrawal_K),
        withdrawal_K20: cloneMeasureValue(view.withdrawal_K20),
        withdrawal_Ca: cloneMeasureValue(view.withdrawal_Ca),
        withdrawal_Mg: cloneMeasureValue(view.withdrawal_Mg),
        rootStock: view.rootStock,
        totalTrees: view.totalTrees,
        treeAge: view.treeAge,
        vigourOfOrchard: view.vigourOfOrchard,
        yearPlanted: view.yearPlanted,
        blockName: newBlockName,
        clientName: view.clientName,
        totalProductionUnitMetric: view.totalProductionUnitMetric,
        totalProductionUnitImperial: view.totalProductionUnitImperial,
        estimateProductionUnitMetric: view.estimateProductionUnitMetric,
        estimateProductionUnitImperial: view.estimateProductionUnitImperial,
        cropId: view.cropId,
        cropNameKey: view.cropNameKey,
        cropNameKeyId: view.cropNameKeyId,
        cropTYpe: view.cropTYpe,
        cultivarId: view.cultivarId,
        cultivarNameKey: view.cultivarNameKey,
        cultivarNameKeyId: view.cultivarNameKeyId,
        imageHigh: view.imageHigh,
        imageLow: view.imageLow,
        irrigation: view.irrigation,
        plantsperSize: view.plantsperSize
    }
}


function cloneCell (cell: CellType): CellType {
    return {
        value: cell.value,
        user: cloneMeasureValue(cell.user),
        standard: cloneMeasureValue(cell.standard),
        liquid: cloneMeasureValue(cell.liquid)
    };
}

function cloneRow (row: Row): Row {
    return {
        desc: row.desc,
        index: row.index,
        month: row.month,
        elements: {
            n: cloneMeasureValue(row.elements.n),
            p: cloneMeasureValue(row.elements.p),
            k: cloneMeasureValue(row.elements.k),
            ca: cloneMeasureValue(row.elements.ca),
            mg: cloneMeasureValue(row.elements.mg),
            s: cloneMeasureValue(row.elements.s),
            fe: cloneMeasureValue(row.elements.fe),
            zn: cloneMeasureValue(row.elements.zn),
            b: cloneMeasureValue(row.elements.b),
            mn: cloneMeasureValue(row.elements.mn),
            cu: cloneMeasureValue(row.elements.cu),
            k10: cloneMeasureValue(row.elements.k10),
            p205: cloneMeasureValue(row.elements.p205)
        }
    };
}

function cloneColumn (row: ProductReference): ProductReference {
    return {
        hexColor: row.hexColor,
        imperialUnit: row.imperialUnit,
        index: row.index,
        key: row.key,
        land: cloneMeasureValue(row.land),
        product: row.product,
        productId: row.productId,
        standard: cloneMeasureValue(row.standard),
        unit: row.unit
    };
}

export function clonePlayFieldState(state: PlayFieldState): PlayFieldState {
    // deep clone all
    const data = state.data;
    return {
        // mark as changed otherwise it won't submit
        change: true,
        loaded: true,
        calculations: playFieldCalculations(data),
        data: {
            cells: data.cells.map(r => r.map(c => cloneCell(c))),
            rows: data.rows.map(r => cloneRow(r)),
            columns: data.columns.map(c => cloneColumn(c)),
            convertSize: data.convertSize,
            system: data.system
            
        }
    }
}


export const playFieldReducer: Reducer<PlayFieldState, PlayFieldStateAction> = (state: PlayFieldState, action: PlayFieldStateAction): PlayFieldState => {
    switch (action.type) {
    case 'recalculateCells':
        return mutatePlayField(state, true, true, d => {
            d.convertSize = action.convertSize
            d.cells = d.cells.map((c, colIndex) => {
                const header = state.data.columns[colIndex]
                if (!header) { return c }

                const sizes = buildConvertData(action.convertSize, header.product.sg)
                return c.map<CellType>((cell, rowIndex) => {
                    return recalculateCell(getCellSafe(d.cells, rowIndex, colIndex), d.system, header, sizes)
                })
            })
        })
    case 'moveColumn':
        // make sure both from and to is in range
        // dispatch action can be launched for one PlayField and applied to others 
        if (!inRange(action.from, state.data.columns) || !inRange(action.to, state.data.columns))
            return state;
        
        return mutatePlayField(state, true, true, d => {
            const delColumns = d.columns.splice(action.from, 1)
            d.columns.splice(action.to, 0, delColumns[0]!)

            // move cells
            const delCells = d.cells.splice(action.from, 1)
            d.cells.splice(action.to, 0, delCells[0]!)

            // must re-assign cells. MemoRows check reference equality
            d.cells = [...d.cells]
        })
    case 'saveChanges':
        return mutate(state, s => {
            s.change = false
        })

    case 'paste':
        return mutatePlayField(state, true, true, d => {
            const clone = [...d.cells]

            // action.data [rows][columns]
            // [
            //  [0,1,2],
            //  [3,4,5]
            // ]const header = d.columns[action.column]!

            walk2d(action.data, (row, column, value) => {
                let num = parseFloat(value.replace(',', '.'))
                if (isNaN(num)) {
                    num = 0
                }
                const mappedCol = column + action.column
                const mappedRow = row + action.row

                if (mappedCol === -2) {
                    // phenelogical / desc column
                    if (!d.rows[mappedRow]) {
                        return
                    }
                        d.rows[mappedRow]!.desc = value
                        return
                }

                if (mappedCol === -1) {
                    if (!d.rows[mappedRow]) {
                        return
                    }

                        // month column
                        d.rows[mappedRow]!.month = value
                }

                const header = d.columns[mappedCol]
                if (!header || !clone[mappedCol] || !clone[mappedCol]![mappedRow]) {
                    return
                }

                clone[mappedCol]![mappedRow] = recalculateCell(clone[mappedCol]![mappedRow]!, d.system, header, buildConvertData(d.convertSize, header.product.sg), num, value)
            })

            d.cells = clone
        })
    case 'set':
        // need to recalculate
        return mutate(state, s => {
            s.loaded = true
            s.data = action.state
            s.calculations = playFieldCalculations(s.data)
        })
    case 'addColumn': {
        const [metricUnit, imperialUnit] = metricImperialUnit(action.system, action.unit);
        
        const columns = arrayPush(state.data.columns, {
            index: state.data.columns.length,
            unit: metricUnit,
            product: action.product,
            standard: measureValue(0, 0),
            land: measureValue(0, 0),
            productId: action.product.id,
            hexColor: action.color,
            imperialUnit: imperialUnit,
            key: max(state.data.columns, c => c.key) + 1
        })

        return mutatePlayField(state, true, true, d => {
            d.columns = columns
            d.cells = resizeCells(columns.length, state.data.rows.length, state.data.cells)
        })
    }
    case 'removeColumn': {
        if (!inRange(action.index, state.data.columns))
            return state;
        
        // need to recalculate
        return mutatePlayField(state, true, true, d => {
            d.columns = arrayRemoveIndex(d.columns, action.index)
            d.cells = arrayRemoveIndex(d.cells, action.index)
        })
    }
    case 'replaceColumn': {
        return mutatePlayField(state, true, true, d => {
            d.columns = d.columns.map(column => {
                if (column.productId === action.seek) {
                    return {
                        ...column,
                        product: action.replace,
                        productId: action.replace.id
                    }
                }
                return column
            })

            // recalculate all cells
            d.cells = d.cells.map((column, columnIndex) => {
                const header = d.columns[columnIndex]!
                return column.map(cell => recalculateCell(cell, d.system, header, buildConvertData(d.convertSize, header.product.sg)))
            })
        })
    }
    case 'updateColumn': {
        // need to recalculate only if action.unit or action.product has changed
        const recalculate = action.unit !== undefined || action.product !== undefined
        if (!inRange(action.index, state.data.columns))
            return state;

        return mutatePlayField(state, true, recalculate, d => {
            d.columns = arrayUpdate(d.columns, action.index, old => {
                const [metricUnit, imperialUnit] = buildUnit(old, action)
                return {
                    index: old.index,
                    standard: old.standard,
                    land: old.land,
                    hexColor: action.color ?? old.hexColor,
                    unit: metricUnit,
                    imperialUnit,
                    product: action.product ?? old.product,
                    productId: action.product?.id ?? old.productId,
                    key: old.key
                }
            })

            const header = d.columns[action.index]!
            // recalc all individual cell standard data in action.index
            d.cells = arrayUpdate(d.cells, action.index, col => {
                return col.map(cell => recalculateCell(cell, d.system, header, buildConvertData(d.convertSize, header.product.sg)))
            })
        })
    }
    case 'addRow': {
        return mutatePlayField(state, true, false, d => {
            d.rows = arrayPush(d.rows, {
                elements: makeProductObject(() => measureValue(0, 0)),
                desc: action.desc,
                month: action.month,
                index: d.rows.length
            })
            d.cells = resizeCells(d.columns.length, d.rows.length, d.cells)
        })
    }
    case 'removeRow': {
        return mutatePlayField(state, true, true, d => {
            d.rows = arrayRemoveIndex(d.rows, action.index).map((c, i) => ({
                ...c,
                index: i
            }))
            d.cells = d.cells.map(row => arrayRemoveIndex(row, action.index))
        })
    }
    case 'updateRow': {
        return mutatePlayField(state, true, false, d => {
            d.rows = arrayUpdate(d.rows, action.index, old => ({
                index: old.index,
                desc: action.desc ?? old.desc,
                month: action.month ?? old.month,
                elements: old.elements
            }))
        })
    }
    case 'blurCellValue': {
        return mutatePlayField(state, false, false, d => {
            const clone = [...d.cells]
            const cell = getCellSafe(clone, action.row, action.column)
            const value = getCellValue(cell, d.system)
            cell.value = formatValue(value, 1)
            d.cells = clone
        })
    }
    case 'setCellValue': {
        return mutatePlayField(state, true, true, d => {
            let num = parseFloat(action.value.replace(',', '.'))
            if (isNaN(num)) {
                num = 0
            }
            const clone = [...d.cells]
            const header = d.columns[action.column]
            if (!header) { return state }

            const cellValue = recalculateCell(getCellSafe(d.cells, action.row, action.column), d.system, header, buildConvertData(d.convertSize, header.product.sg), num, action.value);
            set2dArray(clone, action.column, action.row, cellValue);
            d.cells = clone
        })
    }
    }
}
