export function mapMany<T, Q> (arr: T[], map: (data: T) => Q[]): Q[] {
    return arr.reduce<Q[]>((prev, curr) => [...prev, ...map(curr)], [])
}

export function distinct<T> (arr: T[]): T[] {
    return arr.filter((v, i) => !arr.includes(v, i + 1))
}

export function elementAt<T>(arr: T[], index: number, fallback: T): T {
    return arr[index] ?? fallback;
}

export function arrayToRec<T, K extends string | number | symbol, V>(arr: T[], keySelector: (item:T) => K, valueSelector: (item: T) => V): Record<K, V> {
    return arr.reduce((acc, item) => {
        acc[keySelector(item)] = valueSelector(item)
        return acc
    }, {} as Record<K, V>)
}