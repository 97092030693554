import React, { Dispatch, useContext } from 'react'
import {
    columnsContainsWater,
    getExtraction,
    playFieldReducer,
    PlayFieldState,
    PlayFieldStateAction,
    toConvertSize
} from './playFieldState'
import ViewBlock from './ViewBlock'
import ProductLookUpResponse from '../../controllers/ProductLookUpResponse'
import ElementValues, { FormatValue, getValue } from './Helpers'
import LeafSummary from './LeafSummary'
import SoilSummary from './SoilSummary'
import Withdrawal from './Withdrawal'
import AppContext from '../../appContext'
import PlayfieldTemplate from './PlayfieldTemplate'
import { PlayFieldExtra } from './Program'
import { customUnits, unitCustom } from './CustomUnits'
import PhenologyBlock from "./PhenologyBlock";

const PlayField: React.FC<{
    extra: PlayFieldExtra;
    setExtra: (extra: Partial<PlayFieldExtra>, state?: PlayFieldState) => void;

    state: PlayFieldState;
    stateDispatch: Dispatch<PlayFieldStateAction>;

    products: ProductLookUpResponse[];

    farmName: string;

    tableGrapes: boolean
    addProduct: (product: ProductLookUpResponse, applyToAll: boolean) => void;
    editProduct: (columnIndex: number, product: ProductLookUpResponse, applyToAll: boolean) => void;
}> = (props) => {
    const { initial: { system }, word } = useContext(AppContext)

    return <div className="mx-3 mb-1 border-2 border-gray-100">

        <LeafSummary data={props.extra.leaf} setData={(leaf) => props.setExtra({ leaf })}/>
        <SoilSummary data={props.extra.ground} setData={(ground) => props.setExtra({ ground })}/>

        <PlayfieldTemplate
            state={[props.state, props.stateDispatch]}
            products={props.products}
            tableGrapes={props.tableGrapes}
            addProduct={props.addProduct}
            editProduct={props.editProduct}

            render={{
                viewBlock: () => <ViewBlock farmName={props.farmName} data={props.extra.viewBlock} waterUsed={columnsContainsWater(props.state.data.columns)} update={viewBlock => props.setExtra({ viewBlock }, playFieldReducer(props.state, { type: 'recalculateCells', convertSize: toConvertSize(viewBlock) }))}/>,
                extractions: (rowIndex) => <ElementValues system={system} values={getExtraction(props.state, rowIndex)} emptyColor="text-white"/>,
                totals: () => (<>
                    <tr className="bg-gray-300 font-bold">
                        <td className="border bg-1"/>
                        <td className="border bg-3 text-gray-800">{word('total')} {unitCustom(system, customUnits.kgha_lbAc)}</td>

                        {props.state.calculations.standardTotals.map((s, i) => (
                            <td key={i} className="border">
                                <FormatValue emptyColor="text-gray-300" value={getValue(system, s)} decimalPlaces={1}/>
                            </td>
                        ))}

                        <ElementValues system={system} values={props.state.calculations.elementStdTotals}
                            emptyColor="text-gray-300"/>

                    </tr>

                    {/* <tr className="bg-gray-300 font-bold"> */}
                    {/*     <td className="border bg-1"/> */}
                    {/*     <td className="border bg-3 text-gray-800">{word('total')} {unitCustom(system, customUnits.lHa_gallonAc)}</td> */}

                    {/*     {props.state.calculations.liquidTotals.map((s, i) => ( */}
                    {/*         <td key={i} className="border"> */}
                    {/*             <FormatValue emptyColor="text-gray-300" value={getValue(system, s)}/> */}
                    {/*         </td> */}
                    {/*     ))} */}

                    {/* </tr> */}

                    <tr className="bg-gray-300 font-bold">
                        <td className="border bg-1"/>
                        <td className="border bg-3 text-gray-800">
                            {word('total')} {unitCustom(system, customUnits.kgLand_lbLand)}
                        </td>

                        {props.state.calculations.landTotals.map((s, i) => (
                            <td key={i} className="border">
                                <FormatValue emptyColor="text-gray-300" value={getValue(system, s)} decimalPlaces={1}/>
                            </td>
                        ))}

                        <ElementValues system={system} values={props.state.calculations.elementLandTotals}
                            emptyColor="text-gray-300"/>
                    </tr>
                </>)
            }}
        />

        <div className="flex mt-2">
            <Withdrawal system={system} viewBlock={props.extra.viewBlock} elementTotals={props.state.calculations.elementLandTotals}/>
            {
                props.tableGrapes
                    ? <PhenologyBlock extractions={props.state.calculations.extractions} />
                    : null
            }
        </div>
    </div>
}

export default PlayField
