import React, {MutableRefObject, useContext, useEffect, useMemo, useRef, useState} from 'react'
import './playfield.css'
import PlayFieldResponse from '../../controllers/PlayFieldResponse'
import PlayField from './Playfield'
import PlayFieldLand from '../../controllers/PlayFieldLand'
import { arrayPush, arrayRemoveIndex, arrayUpdate, createArray, mutate } from '../../immutableState'
import ProductLookUpResponse from '../../controllers/ProductLookUpResponse'
import {
    calculateSummary, clonePlayFieldState, cloneViewBlock,
    createPlayFieldState, PlayFieldCalcData,
    playFieldReducer,
    PlayFieldState,
    PlayFieldStateAction,
    stateToPlayField,
    toConvertSize
} from './playFieldState'
import Summary from './Summary'
import { Fertigation } from './Fertigation'
import AppContext from '../../appContext'
import ProgramController from '../../controllers/ProgramController'
import MasterTab from './MasterTab'
import PlayFieldProgram from '../../controllers/PlayFieldProgram'
import PlayFieldLandUpdateRequest from '../../controllers/PlayFieldLandUpdateRequest'
import { classNames, switcher } from '../../wrapper'
import Dialog from '../../components/Dialog'
import GroundData from '../../controllers/GroundData'
import LeafData from '../../controllers/LeafData'
import PlayFieldViewBlock from '../../controllers/PlayFieldViewBlock'
import { comparePlayField } from './compare'
import { createFertigationState, FertigationState, fertiliserReducer, toPumpHouse } from './fertigationCalcs'
import { showSuccessOrFailed } from '../../Snacks'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { defaultUnit } from './units'
import { EditRow, EditTable, focusFirstInput } from '../../components/Fields'
import Input from '../../components/Input'
import { useValidation } from '../../validation'
import MeasurementUnit from '../../controllers/MeasurementUnit'
import useDrag from './drag'
import LeafSummaryDialog from "./LeafSumaryDialog";
import {useShowState} from "../../showState";
import SoilSummaryDialog from "./SoilSummaryDialog";
import {useBlocker, useLocation} from "react-router-dom";
import {windows} from "rimraf";

type ExtendNavigator = Navigator & Pick<History, 'block'>;


type ActiveTab = 'master' | 'summary' | 'fertigation' | number;

function nextTab (current: ActiveTab, count: number): ActiveTab {
    switch (current) {
    case 'master':
        return count > 0 ? 0 : 'summary'
    case 'summary':
        return 'fertigation'
    case 'fertigation':
        return 'master'
    }
    return current < count - 1 ? current + 1 : 'summary'
}

function prevTab (current: ActiveTab, count: number): ActiveTab {
    switch (current) {
    case 'master':
        return 'fertigation'
    case 'summary':
        return count > 0 ? count - 1 : 'master'
    case 'fertigation':
        return 'summary'
    }
    return current > 0 ? current - 1 : 'master'
}

// ground ground, leaf and viewBlock as a one unit
// pass only one instance getter and setter to playField
export interface PlayFieldExtra {
    ground: GroundData[];
    leaf: LeafData[];
    viewBlock: PlayFieldViewBlock;
    changed: boolean;
}

enum TabPlayFieldCondition {
    Loaded,
    Created
}

// each playField tab
export interface TabPlayField {
    condition: TabPlayFieldCondition;
    readonly data: PlayFieldLand;
    state: PlayFieldState;
    extra: PlayFieldExtra;
}

function tabCheckBox (checked: boolean, clicked: () => void) {
    return <div className="ml-1 inline-block" onClick={e => {
        clicked()
        e.stopPropagation()
    }}>
        <div
            className="align-middle w-4 h-4 inline-block relative border select-none bg-gray-200 border-gray-800 mr-1">
            <input className="opacity-0 w-0 h-0 absolute" type="checkbox" checked={checked} onChange={() => clicked()}/>
            <div className="checkbox-mark absolute inset-0 hover:bg-primary-200"/>
        </div>
    </div>
}

function toCalcLands (lands: TabPlayField[]): PlayFieldCalcData[] {
    return lands.map(l => ({
        viewBlock: l.extra.viewBlock,
        data: l.state.data,
        landId: l.data.landId
    }))
}

// combine state and ref
// ref to to be able to access state in dormant scopes like accessing state in a window event listener only bounded once.
// and state to trigger UI updates.
function useStateRef<T> (initial: T): [MutableRefObject<T>, (data: T) => void] {
    const [, setState] = useState<T>(initial)
    const ref = useRef<T>(initial)

    return [ref, data => {
        setState(data)
        ref.current = data
    }]
}

function buildTabPlayField (condition: TabPlayFieldCondition, land: PlayFieldLand, system: MeasurementUnit, products: ProductLookUpResponse[], checkChange: boolean): TabPlayField {
    const convert = toConvertSize(land.viewBlock)
    let state = createPlayFieldState(land.data, convert, system, products)
    // there is a difference between the original data and the recalculated. Mark as such
    state.change = checkChange ? !comparePlayField(state, land) : true

    return {
        condition,
        data: land,
        state,
        extra: {
            changed: false,
            ground: land.groundData,
            leaf: land.leafData,
            viewBlock: land.viewBlock
        }
    }
}

function cloneExtra (extra: PlayFieldExtra, newBlockName: string): PlayFieldExtra {
    return {
        changed: true,
        // do not clone ground and leaf data
        ground: [],
        leaf: [],
        viewBlock: cloneViewBlock(extra.viewBlock, newBlockName)
    };
}

function cloneLand (source: TabPlayField, newBlockName: string): TabPlayField {
    return {
        condition: TabPlayFieldCondition.Created,
        // mark id as default for the backend to know it should be inserted
       
        data: {
            
            ...source.data,
            // landId will use used to clone the land
            id: 0,
            groundData: [],
            leafData: []
        },
        state: clonePlayFieldState(source.state),
        extra: cloneExtra(source.extra, newBlockName)
    }
}

const Program: React.FC<{
    data: PlayFieldResponse;

    // reset readyForApproval and approved
    onSave?: () => void;

    // onChange?: (data: PlayFieldProgram) => void;
    id: number;
    footer?: React.ReactNode;
}> = (props) => {
    const app = useContext(AppContext)
    const [products, setProducts] = useState<ProductLookUpResponse[]>(props.data.products)
    const [lands, setLands] = useState<TabPlayField[]>(() => props.data.lands.map(l => buildTabPlayField(TabPlayFieldCondition.Loaded, l, app.initial.system, props.data.products, true)))
    const landsToBeDeleted = useRef<number[]>([])
    const landsMoved = useRef<TabPlayField[]>([])
    const landsRenamed = useRef<TabPlayField[]>([])
    const [fertigation, setFertigation] = useState<FertigationState[]>(() => props.data.pumphouses.map(p => createFertigationState(p, toCalcLands(lands), products)))

    const [program, setProgram] = useState<PlayFieldProgram>(props.data.program)
    const programRef = useRef<PlayFieldProgram>(props.data.program)

    const [warningPopup, setWarningPopup] = useState(false)

    const [showCopyLand, setShowCopyLand] = useState<boolean>(false)
    const [showRenameLand, setShowRenameLand] = useState<boolean>(false)
    const [showDeleteLand, setShowDeleteLand] = useState<boolean>(false)
    const [tab, setTab] = useState<ActiveTab>('master')
    const [currentTab, setCurrentTab] = useState<ActiveTab>(tab)

    const [clonedLand, setClonedLand] = useState<{ name: string,  index: number }>({
        name: '',
        index: -1
    })
    const indexRef = useRef<number>(-1)
    const [renameLand, setRenameLand] = useState<string>('')

    // block the navigation when we leave the program?
    const fertigationChanged = useRef(false)
    const masterTabChanged = programRef.current !== program
    const landsChanged = lands.some(l => l.state.change || l.condition == TabPlayFieldCondition.Created || l.extra.changed)
        || landsToBeDeleted.current.length > 0 || landsMoved.current.length > 0 || landsRenamed.current.length > 0 || fertigationChanged.current

    const somethingChanged = landsChanged || masterTabChanged
    
    const leafUploads = useShowState<LeafData[]>([]);
    const soilUploads = useShowState<GroundData[]>([]);
    
    // when refreshing the page check if there was any unsaved changes and if there where show the blocker
    const location = useLocation();
    useEffect(() => {
        if (somethingChanged) {
            
        }
    }, [location])

    const blocker = useBlocker(tx => {
        return somethingChanged
    });

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (somethingChanged) {
                event.preventDefault();
            }
        };
        
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [somethingChanged]);


    function updateLandIndex (index: number, update: Partial<TabPlayField>) {
        setLands(arrayUpdate(lands, index, old => ({
            condition: old.condition,
            state: update.state ?? old.state,
            extra: update.extra ?? old.extra,
            data: update.data ?? old.data
        })))
    }

    function addProductIfNotExists (add: ProductLookUpResponse) {
        if (products.findIndex(p => p.id === add.id) === -1) {
            setProducts(arrayPush(products, add))
        }
    }

    function addProduct (landIndex: number, land: TabPlayField, product: ProductLookUpResponse, applyToAll: boolean) {
        addProductIfNotExists(product)

        let initUnit = defaultUnit(app.initial.system, product.solid)

        const action: PlayFieldStateAction = {
            type: 'addColumn',
            product,
            unit: initUnit,
            color: '',
            system: app.initial.system
        }

        if (applyToAll) {
            setLands(lands.map(i => ({
                condition: i.condition,
                state: playFieldReducer(i.state, action),
                extra: i.extra,
                data: i.data
            })))
        } else {
            handleUpdate(action, landIndex)
        }
    }

    function editProduct (landIndex: number, land: TabPlayField, columnIndex: number, product: ProductLookUpResponse, applyToAll: boolean) {
        addProductIfNotExists(product)

        if (applyToAll) {
            // change all product of land column [columnIndex] of new product
            const existingProduct = land.state.data.columns[columnIndex]
            if (!existingProduct) {
                return
            }

            setLands(lands.map(i => ({
                state: playFieldReducer(i.state, {
                    type: 'replaceColumn',
                    seek: existingProduct.productId,
                    replace: product
                }),
                condition: i.condition,
                extra: i.extra,
                data: i.data
            })))
        } else {
            updateLandIndex(landIndex, {
                state: playFieldReducer(land.state, {
                    type: 'updateColumn',
                    index: columnIndex,
                    product
                })
            })
        }
    }

    function saveProgram (): Promise<void> {
        // determine what in lands has changed
        const landsTUpdate = lands.map<PlayFieldLandUpdateRequest>(land => {
            const all = land.condition === TabPlayFieldCondition.Created

            return {
                leafData: land.extra.leaf === land.data.leafData && !all ? null : land.extra.leaf,
                groundData: land.extra.ground === land.data.groundData && !all ? null : land.extra.ground,
                viewBlock: land.data.viewBlock === land.extra.viewBlock && !all ? null : land.extra.viewBlock,
                data: land.state.change || all ? stateToPlayField(land.state) : null,
                id: land.data.id,
                landId: land.data.landId,
                playFieldId: land.data.playFieldId
            }
        })

        // recalc fertigation
        const recalculated = fertigation.map(f => fertiliserReducer(f, {
            'type': 'recalculate',
            lands: toCalcLands(lands),
            products
        }))

        const call = ProgramController.save({
            deleteLands: landsToBeDeleted.current,
            programId: props.data.program.id,
            master: program === props.data.program ? null : program,
            lands: landsTUpdate,
            summary: calculateSummary(toCalcLands(lands), products),
            fertigation: {
                programId: props.data.program.id,
                pumphouses: recalculated.map(f => toPumpHouse(f))
            }
        })
        return showSuccessOrFailed(app, call, app.word('playField_saved')).then(resp => {

            // reset approval states
            props.onSave?.()
            fertigationChanged.current = false
            // update ref of program to determine next changed
            programRef.current = program

            // clear lands to be deleted
            landsToBeDeleted.current = []

            // clear moved lands
            landsMoved.current = []

            // clear renamed lands
            landsRenamed.current = []

            // set modified to false on all
            setLands(lands.map((land, index) => ({
                condition: TabPlayFieldCondition.Loaded,
                state: playFieldReducer(land.state, { 'type': 'saveChanges' }),
                data: {
                    ...land.data,
                    id: resp[index]!,
                    viewBlock: land.extra.viewBlock,
                    groundData: land.extra.ground,
                    leafData: land.extra.leaf,
                },
                extra: {...land.extra, changed: false}
            })))
        })

    }

    const [selectedTab, setSelectedTabs] = useStateRef<number[]>([])

    function handleUpdate (action: PlayFieldStateAction, landIndex: number) {
        setLands(lands.map((land, index) => {
            if (selectedTab.current.indexOf(index) !== -1 || index === landIndex) {
                return {
                    ...land,
                    state: playFieldReducer(land.state, action)
                }
            }
            return land
        }))
    }

    function toggleSelected (landIndex: number) {
        const index = selectedTab.current.indexOf(landIndex)
        // toggle between select and deselect
        if (index === -1) {
            setSelectedTabs(arrayPush(selectedTab.current, landIndex))
        } else {
            setSelectedTabs(arrayRemoveIndex(selectedTab.current, index))
        }
    }

    function tabClicked (event: React.MouseEvent<HTMLDivElement>, key: ActiveTab) {
        if (typeof key === 'number' && (event.altKey || event.ctrlKey)) {
            toggleSelected(key)
            return
        }
        setTab(key)
        // when visiting the fertigation tab recalc
        if (key === 'fertigation') {
            setFertigation(fertigation.map(f => fertiliserReducer(f, {
                'type': 'recalculate',
                lands: toCalcLands(lands),
                products
            })))
        }

    }

    const validationClone = useValidation({
        uniqueNameRequired: () => clonedLand.name.length > 0 && !lands.find(l => l.data.viewBlock.blockName.trim() === clonedLand.name.trim()),
    })
    const validationRename = useValidation({
        nameRequired: () => renameLand.length > 0 && (!(lands.filter(l => l.data.viewBlock.blockName.trim() === renameLand.trim()).length > 0) || lands[indexRef.current]?.data.viewBlock.blockName.trim() === renameLand.trim()),
    })

    const drag = useDrag<number>('playfield-tabs', (target, dest) => {
        arrayMove(target, dest);
        setTab(dest);
    });

    function arrayMove (oldIndex: number, newIndex: number) {
        let cloneLands = [...lands]
        // remove old element from the old place
        const element = cloneLands[oldIndex]
        cloneLands.splice(oldIndex, 1)
        // add old element to the new place
        if (!element) return
        landsMoved.current.push(element)
        cloneLands.splice(newIndex, 0, element)
        setLands(cloneLands)
    }

    function renderTab (key: ActiveTab, name: string, modified: boolean, checkbox: React.ReactNode, deleteAndCopyLand?: boolean) {
        const style = key === tab ? 'flex bg-white px-2 py-1 border-l border-r border-r-4 border-gray-300 rounded-t border-l-gray-300  border-t-2 border-t-primary-600 cursor-pointer' : 'flex px-2 py-1 border-r-4 border-gray-100 cursor-pointer'

        const dragHighlight = typeof key === 'number' && drag.isOver(key) && drag.currentDrag != null;

        return <div key={key}
                    className={classNames(style,
                        drag.currentDrag === key  ? 'opacity-50' : '',
                        dragHighlight && drag.currentDrag! > (key as number)  ? 'border-l-4 border-l-gray-500' : '',
                        dragHighlight && drag.currentDrag! < (key as number)  ? 'border-r-4 border-r-gray-500' : '',
                    )}
                    onClick={event => tabClicked(event, key)}
                    draggable={deleteAndCopyLand}

                    {...(typeof key === 'number' ? drag.targetAndDest(key) : {})}

                    onDoubleClick={() => {
                        if (typeof key !== 'number') return
                        setShowRenameLand(true)
                        indexRef.current = key
                        const land = lands[indexRef.current]
                        if (!land) return
                        setRenameLand(land.extra.viewBlock.blockName)
                    }}
        >
            {checkbox}
            <span className="text-red-500">{modified ? '*' : ' '}</span>
            <span className="px-2 align-middle text-black whitespace-nowrap">{name}</span>
            {deleteAndCopyLand ?
                <div className="flex items-center">
                    <div className='w-5' onClick={e => {
                        setClonedLand({
                            name,
                            index: typeof (key) === 'number' ? key : -1
                        })
                        setShowCopyLand(true)
                        e.stopPropagation()
                    }} title={app.word('copy_land')}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 hover:h-5 hover:w-5" fill="none"
                             viewBox="0 0 24 24"
                             stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"/>
                        </svg>
                    </div>
                    <div className="text-red-500 w-5" onClick={e => {
                        setCurrentTab(key)
                        setShowDeleteLand(true)
                        e.stopPropagation()
                    }} title={app.word('delete_land')}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 hover:h-5 hover:w-5" fill="none"
                             viewBox="0 0 24 24"
                             stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </div>
                </div>
                : null}
        </div>
    }

    function renderPlayField (index: number): React.ReactNode {
        const land = lands[index]
        if (!land) return null

        

        return <PlayField
            extra={land.extra}
            farmName={program.farmName}
            setExtra={(data, state) => {
                let landData = land.data
                if (data.viewBlock) {
                    landData = mutate(land.data, d => {
                        d.viewBlock.blockName = data.viewBlock?.blockName ?? d.viewBlock.blockName
                    })
                }
                updateLandIndex(index, {
                    extra: {
                        changed: true,
                        ground: data.ground ?? land.extra.ground,
                        leaf: data.leaf ?? land.extra.leaf,
                        viewBlock: data.viewBlock ?? land.extra.viewBlock
                    },
                    state: state,
                    data: landData
                })
            }}
            state={land.state}
            stateDispatch={action => handleUpdate(action, index)}
            tableGrapes={program.tableGrapes}
            products={products}
            addProduct={(product, all) => addProduct(index, land, product, all)}
            editProduct={(colIndex, product, all) => editProduct(index, land, colIndex, product, all)}
            key={land.data.landId}
        />
    }

    function saveClonedLand () {
        if (!validationClone.validate()) {
            return
        }
        setShowCopyLand(false)
        if (!clonedLand || !lands[clonedLand.index]) {
            return
        }

        const clone = cloneLand(lands[clonedLand.index]!, clonedLand.name);
        setLands(arrayPush(lands, clone))
    }

    function deleteLand () {
        const land = lands[currentTab as number]!
        landsToBeDeleted.current.push(land.data.id)
        setLands(lands.filter(l => l !== land))
        setShowDeleteLand(false)
        setTab(prevTab(tab, lands.length))
    }

    function saveChangedName () {
        if (!validationRename.validate()) {
            return
        }
        setLands(arrayUpdate(lands, indexRef.current, item => {
            item.extra.viewBlock = {...item.extra.viewBlock, blockName: renameLand};
        }));
        const copyLand = lands[indexRef.current]
        if (copyLand)
            landsRenamed.current.push(copyLand)

        setShowRenameLand(false)
    }

    function updateLandExtra<T extends {landId: number}>(landsIds: T[], update: (extra: PlayFieldExtra, data: T[]) => Partial<PlayFieldExtra>) {
        setLands(lands.map(l => {
            const toAdd = landsIds.filter(f => f.landId === l.data.id);
            if (toAdd.length > 0) {
                return {
                    ...l,
                    extra: {
                        ...l.extra,
                        ...update(l.extra, toAdd),
                        changed: true,
                    }
                }
            }
            return l;
        }));
    }
    
    
    return <div>

        <Dialog mounted={focusFirstInput} title={app.word('copy_land')} show={showCopyLand} setShow={setShowCopyLand}
                body={
                    <div className="m-3">
                        <EditTable discard={() => setShowCopyLand(false)} save={() => saveClonedLand()}
                                   saveWord={'copy'}>
                            {EditRow(app.word('name'),
                                <Input value={clonedLand.name} change={v => setClonedLand({
                                    ...clonedLand,
                                    name: v
                                })}/>,
                                validationClone.rules.uniqueNameRequired,
                                app.word('unique_name_required'))}
                        </EditTable>
                    </div>
                }/>

        <Dialog mounted={focusFirstInput} title={app.word('rename_land')} show={showRenameLand}
                setShow={setShowRenameLand}
                body={
                    <div className="m-3">
                        <EditTable discard={() => setShowRenameLand(false)} save={saveChangedName}
                                   saveWord={'rename'}>
                            {EditRow(app.word('name'),
                                <Input value={renameLand} change={v => setRenameLand(v)}/>,
                                validationRename.rules.nameRequired,
                                app.word('unique_rename_required'))}
                        </EditTable>
                    </div>
                }/>

        <Dialog title={app.word('warning')} show={showDeleteLand} setShow={setShowDeleteLand} body={
            <div className="mt-5">
                <div
                    className="mx-2">{app.word('are_you_sure_you_want_to_delete_this')} {app.wordUnit('land', 'ranch')}?
                </div>
                <div className="flex m-4 justify-end">
                    <div className="btn bg-primary" onClick={() => deleteLand()}>{app.word('yes')}</div>
                    <div className="btn bg-red-500" onClick={() => setShowDeleteLand(false)}>{app.word('no')}</div>
                </div>
            </div>
        }/>

        <Dialog title={app.word('warning')} show={warningPopup} setShow={setWarningPopup}
                body={<div className="p-2 text-xl">
                    <div>{app.word('must_resubmit_program_for_approval')}. {app.word('would_you_like_to_continue')}?</div>
                    <div className="flex m-2 text-white justify-center">
                        <button className="bg-primary p-2 rounded m-1 px-4" onClick={() => {
                            saveProgram()
                            setWarningPopup(false)
                        }}>
                            {app.word('yes')}
                        </button>
                        <button className="bg-error-500 p-2 rounded m-1 px-4" onClick={() => setWarningPopup(false)}>
                            {app.word('no')}
                        </button>
                    </div>
                </div>}/>

        <Dialog title={app.word('save_changes')}
                show={blocker.state == 'blocked'}
                setShow={state => blocker.reset?.()}
                body={
                    <div className="p-4">
                        <div className="text-center mb-4">
                            <div>{app.word('unsaved_changes_lost')}.</div>
                            <div>{app.word('save_before_closing')}?</div>
                        </div>
                        <div className="flex w-full justify-between items-center">
                            <div className="uppercase text-error-400 pr-4 cursor-pointer underline"
                                 onClick={() => blocker.proceed?.()}>
                                {app.word('discard_changes')}
                            </div>
                            <div>
                                <div className="btn bg-primary"
                                     onClick={() => {
                                         saveProgram().then(() => {
                                             blocker.proceed?.();
                                         });
                                     }}>
                                    {app.word('save')}
                                </div>
                            </div>
                        </div>
                    </div>
                }
        />

        <div className="sticky top-0 z-10">
            <div className="bg-gray-100 flex overflow-x-scroll pt-1 text-sm items-center overflow-hidden">
                <div className="px-4"></div>
                <div className="cursor-pointer">
                    <ChevronLeftIcon className="text-gray-500 h-4" onClick={() => setTab(prevTab(tab, lands.length))}/>
                </div>
                <div className="cursor-pointer">
                    <ChevronRightIcon className="text-gray-500 h-4" onClick={() => setTab(nextTab(tab, lands.length))}/>
                </div>

                {tabCheckBox(selectedTab.current.length === lands.length, () => {
                    if (selectedTab.current.length > 0) {
                        setSelectedTabs([])
                    } else {
                        setSelectedTabs(createArray(lands.length, i => i))
                    }
                })}

                <div className="w-4"></div>

                {renderTab('master', app.word('master'), masterTabChanged, null)}
                {lands.map((l, landIndex) => renderTab(landIndex, l.extra.viewBlock.blockName, l.state.change,
                    tabCheckBox(tab === landIndex || selectedTab.current.indexOf(landIndex) !== -1, () => toggleSelected(landIndex)), true))}
                {renderTab('summary', app.word('summary'), landsChanged, null)}
                {renderTab('fertigation', app.word('fertigation'), landsChanged, null)}

            </div>
        </div>

        {switcher(tab, c => c
            .case('master', () => <MasterTab program={program} onChange={p => setProgram(p)}/>)
            .case('summary', () => <Summary data={calculateSummary(toCalcLands(lands), products)} products={products}/>)
            .case('fertigation', () => <Fertigation state={fertigation} setState={s => {
                fertigationChanged.current = true;
                setFertigation(s);
            }} products={products}
                                                    lands={toCalcLands(lands)}/>)
            .default(() => renderPlayField(tab as number))
        )}

        <div className="text-right pr-8">
            <div className="inline-block underline px-2 cursor-pointer" onClick={() => leafUploads.show([])}>{app.word("upload_leaf")}</div>
            <div className="inline-block underline px-2 cursor-pointer" onClick={() => soilUploads.show([])}>{app.word("upload_soil")}</div>
        </div>

        <Dialog title="Leafs" show={leafUploads.visible} setShow={leafUploads.hide}>
            <LeafSummaryDialog 
                lands={lands.map(l => ({id: l.data.id, name: l.data.viewBlock.blockName}))} 
                data={leafUploads.data} 
                onSave={leaf => {
                    updateLandExtra(leaf, (extra, data) => ({
                        leaf: extra.leaf.concat(data)
                    }))
                    leafUploads.hide()
                }} 
                onDiscard={leafUploads.hide}
            />
        </Dialog>
        
        <Dialog title="Soil" show={soilUploads.visible} setShow={soilUploads.hide}>
            <SoilSummaryDialog
                lands={lands.map(l => ({id: l.data.id, name: l.data.viewBlock.blockName}))} 
                data={soilUploads.data} 
                onSave={soil => {
                    updateLandExtra(soil, (extra, data) => ({
                        ground: extra.ground.concat(data)
                    }))
                    soilUploads.hide()
                }} 
                onDiscard={soilUploads.hide}
            />
        </Dialog>
        
        
        <div className="text-right pr-8">
            {somethingChanged ?
                <div className="btn bg-primary" onClick={() => {
                    if (props.data.program.readyForApproval || props.data.program.approved) {
                        setWarningPopup(true)
                    } else {
                        saveProgram()
                    }
                }}>{app.word('save_all')}</div>
                : null}

            {props.footer}

        </div>

    </div>
}

export default Program
