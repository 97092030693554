import { PlayFieldState } from './playFieldState'
import PlayFieldLand from '../../controllers/PlayFieldLand'
import MeasureValue from '../../controllers/MeasureValue'
import ProductObject from '../../controllers/ProductObject'
import { makeProductObject } from './Helpers'

function compareValue (a: MeasureValue, b: MeasureValue): boolean {
    return a.imperial === b.imperial && a.metric === b.metric
}

function compareProductObject<T> (a: ProductObject<T>, b: ProductObject<T>, comparer: (a: T, b: T) => boolean): boolean {
    const allKeys: (keyof ProductObject<T>)[] = [
        'ca',
        'mn',
        'b',
        'cu',
        'fe',
        'k',
        'k10',
        'mg',
        'n',
        'p',
        'p205',
        's',
        'zn']

    for (const key of allKeys) {
        if (!comparer(a[key], b[key])) {
            return false
        }
    }
    return true
}

export function comparePlayField ({ calculations: calcs }: PlayFieldState, { data: land }: PlayFieldLand): boolean {
    if (calcs.standardTotals.length !== land.columns.length) { return false }
    if (calcs.landTotals.length !== land.columns.length) { return false }
    if (calcs.extractions.length !== land.rows.length) { return false }

    // first compare standard and land totals
    for (let i = 0; i < land.columns.length; i++) {
        if (!compareValue(calcs.standardTotals[i]!, land.columns[i]!.standard)) { return false }

        if (!compareValue(calcs.landTotals[i]!, land.columns[i]!.land)) { return false }
    }

    // extractions
    for (let i = 0; i < land.rows.length; i++) {
        if (!compareProductObject(calcs.extractions[i]!, land.rows[i]!.elements, compareValue)) {
            return false
        }
    }

    if (!compareProductObject(calcs.elementStdTotals, makeProductObject(key => land.elementTotals[key].standard), compareValue)) { return false }
    if (!compareProductObject(calcs.elementLandTotals, makeProductObject(key => land.elementTotals[key].land), compareValue)) { return false }

    return true
}
