import PagedSearchTable, { PagedTableFunctions } from '../components/PagedSearchTable'
import React, { useContext, useEffect, useRef, useState } from 'react'
import ProgramController from '../controllers/ProgramController'
import ProgramHistoryResponseList from '../controllers/ProgramHistoryResponseList'
import { dateFormat } from '../date'
import { useNavigate } from 'react-router-dom'
import AppContext from '../appContext'
import SummaryType from '../controllers/SummaryType'
import PermissionEnum from '../controllers/PermissionEnum'
import Success from '../components/Success'
import Warning, { useWarningState } from './program/Warning'
import { showSuccessOrFailed } from '../Snacks'
import Dialog from '../components/Dialog'
import CheckBox from '../components/CheckBox'
import ProductSummaryDisplay from '../controllers/ProductSummaryDisplay'
import Tabs from '../components/Tabs'
import PaginationRequestSearch from '../controllers/PaginationRequestSearch'
import PaginationResponse from '../controllers/PaginationResponse'
import { EditRow, EditTable } from '../components/Fields'
import Input from '../components/Input'
import ProgramSearchFilter from './ProgramSearchFilter'
import ProgramFilter from '../controllers/ProgramFilter'
import { AutoComplete } from '../components/AutoComplete'
import { useStateAjax } from '../wrapper'
import UserController from '../controllers/UserController'
import {AdjustmentsHorizontalIcon} from "@heroicons/react/24/outline";


const Programs: React.FC = () => {
        const context = useContext(AppContext)

        return (
            <Tabs tabs={[
                {
                    header: () => context.word('current'),
                    key: 'current',
                    content: <ProgramHistory key="current1" />
                },
                {
                    header: () => context.word('archives'),
                    key: 'archives',
                    content: <ProgramHistoryArchives key="archive1" url={ProgramController.archives} />
                },
            ]} />
        )
    }

    export default Programs

    const Download: React.FC<{
        id: number;
        downloadPdf: (type: SummaryType, id: number) => void,
        downloadExcel: (type: SummaryType, id: number) => void;
    }> = (props) => {
        const app = useContext(AppContext)

        return (
            <div className="flex">
                <div className="flex ml-3">
                    <select
                        className="w-[48px] h-[39px] bg-transparent group-hover:bg-gray-200 bg-no-repeat appearance-none"
                        value={'empty'} style={{ backgroundImage: 'url(/images/pdf.png)' }}
                        onChange={e => {
                            props.downloadPdf(e.target.value as SummaryType, props.id)
                        }}>
                        <option value="empty" className="hidden" disabled={true}></option>
                        <option value={SummaryType.Normal}>{app.word('normal')}</option>
                        <option value={SummaryType.Jdv}>{app.word('jdv_summary')}</option>
                        <option value={SummaryType.Mdv}>{app.word('mdv')}</option>
                        <option value={SummaryType.Normal2}>{app.word('normal2')}</option>
                        <option value={SummaryType.Jdv2}>{app.word('jdv_summary2')}</option>
                        <option value={SummaryType.Mdv2}>{app.word('mdv2')}</option>
                    </select>
                </div>
                <div className="flex mx-1">
                    <select
                        className="w-[48px] h-[39px] bg-transparent group-hover:bg-gray-200 bg-no-repeat appearance-none"
                        value={'empty'} style={{ backgroundImage: 'url(/images/excel.png)' }}
                        onChange={e => {
                            props.downloadExcel(e.target.value as SummaryType, props.id)
                        }}>
                        <option value="empty" className="hidden" disabled={true}></option>
                        <option value={SummaryType.Normal}>{app.word('normal')}</option>
                        <option value={SummaryType.Jdv}>{app.word('jdv_summary')}</option>
                        <option value={SummaryType.Mdv}>{app.word('mdv')}</option>
                    </select>
                </div>
            </div>
        )
    }


    const ProgramHistory: React.FC = (props) => {
        const navigate = useNavigate()
        const context = useContext(AppContext)
        const pagedTableRef = useRef<PagedTableFunctions<ProgramHistoryResponseList>>()
        
        const currentSammaryType = useRef<SummaryType>(SummaryType.Mdv);

        const [products, setProducts] = useState<ProductSummaryDisplay[]>([])
        const [showProducts, setShowProducts] = useState(false)
        const [showRename, setShowRename] = useState(false)
        const [showCopy, setShowCopy] = useState(false)
        const [rename, setRename] = useState<{ name: string, id: number }>({ name: '', id: 0 })
        const [copy, setCopy] = useState<{ name: string, id: number,userId: number }>({ name: '', id: 0 ,userId:context.initial.userId})
        const [selectedProducts, setSelectedProducts] = useState<number[]>([])
        const programId = useRef<number>(-1)
        const mdvExcel = useRef<boolean>(false)
        const [users] = useStateAjax(() => ProgramController.byUserRegions())
        
        const warningState = useWarningState<number>(-1)

        const programFilter = useRef<ProgramFilter | null>(null);

        const [showFilter, setShowFilter] = useState(false);
        
        const [toOtherUser, setToOtherUser] = useState(false);

        function rowRemove(id: number) {
            showSuccessOrFailed(context, ProgramController.remove({ id }), 'successfully_deleted', 'failed_to_delete').then(() => {
                pagedTableRef.current?.refresh()
            })
        }

        function mdvDownload() {
            setShowProducts(false)
            context.showSnack(<Success title={context.word('download_start_shortly')} />)
            if (mdvExcel.current) {
                window.location.href = ProgramController.urls.excel(programId.current, SummaryType.Mdv, selectedProducts)
            } else {
                window.location.href = ProgramController.urls.pdf(programId.current, currentSammaryType.current, false, selectedProducts)
            }
        }

        function downloadPdf(type: SummaryType, id: number) {
            currentSammaryType.current = type;
            // for MDV first show select products popup
            if (type === SummaryType.Mdv || type === SummaryType.Mdv2) {
                programId.current = id
                mdvExcel.current = false
                setShowProducts(true)
                ProgramController.summaryProducts({ id }).then(resp => {
                    setProducts(resp)
                })
                return
            }

            context.showSnack(<Success title={context.word('download_start_shortly')} />)
            window.location.href = ProgramController.urls.pdf(id, type, false, [])
        }

        function downloadExcel(type: SummaryType, id: number) {
            currentSammaryType.current = type;
            if (type === SummaryType.Mdv) {
                programId.current = id
                mdvExcel.current = true
                setShowProducts(true)
                ProgramController.summaryProducts({ id }).then(resp => {
                    setProducts(resp)
                })
                return
            }

            context.showSnack(<Success title={context.word('download_start_shortly')} />)
            window.location.href = ProgramController.urls.excel(id, type, [0])
        }

        function programClick(row: ProgramHistoryResponseList) {
            navigate(`/program/${row.id}`)
        }

        function rowRemoveWarning(id: number) {
            warningState.show(`${context.word('removing')}?`, id)
        }

        function saveRename(id: number, name: string) {
            showSuccessOrFailed(context, ProgramController.rename({ id, name }), 'successfully_renamed', 'failed_to_rename').then(() => {
                pagedTableRef.current?.refresh()
            })
            setShowRename(false)
        }
        function saveCopy(id: number, name: string, userId: number) {
            showSuccessOrFailed(context, ProgramController.clone({id, name, userId}), 'successfully_copied', 'failed_to_copy').then(() => {
                pagedTableRef.current?.refresh()
            })
            setShowCopy(false)
        }

        return <>
            <Dialog title={context.word('copy_program')} show={showCopy} setShow={setShowCopy} body={<div>
                <EditTable save={() => saveCopy(copy.id, copy.name,copy.userId)} discard={() => setShowCopy(false)} saveWord={'save'}>
                    {EditRow(
                        context.word('name'),
                        <Input value={copy.name} change={v => setCopy({ ...copy, name: v })} />
                        )}
                    {EditRow (context.word('copy_to_other_user'),
                        <CheckBox value={toOtherUser} 
                                  onChange={(e) => {
                                      setToOtherUser(e)
                                      if (!e) {
                                            setCopy({
                                                ...copy,
                                                userId: context.initial.userId
                                            })
                                      }
                                  }}/>)}
                    {
                        toOtherUser 
                            ? EditRow (context.word('to_user'),
                                <AutoComplete
                                    options={users}
                                    textFunc={u => u.name}
                                    valueFunc={u => u.id}
                                    value={copy.userId}
                                    onChange={val => {
                                        setCopy({
                                            ...copy,
                                            userId: val
                                        })
                                    }}/>
                            )
                            : null
                    }
                </EditTable>
            </div>} />
            <Dialog title={context.word('rename_program')} show={showRename} setShow={setShowRename} body={<div>
                <EditTable save={() => saveRename(rename.id, rename.name)} discard={() => setShowRename(false)} saveWord={'rename'}>
                    {EditRow(
                        context.word('name'),
                        <Input value={rename.name} change={v => setRename({ ...rename, name: v })} />
                    )}
                </EditTable>
            </div>} />

            <Dialog title={context.word('select_products')} body={<div className="p-4">

                {products.map(p => <label className="block" key={p.id}>
                    <div className="inline-block w-48">{p.name}</div>
                    <CheckBox value={selectedProducts.indexOf(p.id) !== -1} renderLabel={false} onChange={state => {
                        if (state) {
                            setSelectedProducts([...selectedProducts, p.id])
                            return
                        }
                        setSelectedProducts(selectedProducts.filter(s => s !== p.id))
                    }}/>
                </label>
                )}

                <div className="text-right mt-2">
                    <div className="btn bg-red-500"
                        onClick={() => setShowProducts(false)}>{context.word('cancel')}</div>
                    <div className="btn bg-primary-500" onClick={() => mdvDownload()}>{context.word('submit')}</div>
                </div>

            </div>} show={showProducts} setShow={setShowProducts} />

            <Warning state={warningState} onYes={rowRemove} />
            <div className="btn bg-primary m-2"
                onClick={() => navigate('/new-program')}>{context.word('new_program')}</div>


            <PagedSearchTable<ProgramHistoryResponseList>
                call={request => ProgramController.history({ ...request, filter: showFilter ? programFilter.current : null })}
                rowClick={programClick}
                componentRef={pagedTableRef}
                columns={[
                    {
                        header: context.word('name'),
                        row: (item) => item.name
                    },
                    {
                        header: context.word('playfields'),
                        row: (item) => item.playfieldCount
                    },
                    {

                        header: context.word('created'),
                        row: (item) => (
                            <>
                                <div className="text-l text-gray-800">{item.createdBy}</div>
                                <div className="text-xs text-gray-500">{dateFormat(item.createdOn)}</div>
                            </>
                        )
                    },
                    {
                        header: context.word('updated'),
                        row: (item) => dateFormat(item.lastUpdated)
                    },
                    {
                        header: context.word('submitted'),
                        row: (item) =>
                            <>
                                <div className="text-l text-gray-800">{item.submittedTo}</div>
                                <div className="text-xs text-gray-500">{dateFormat(item.submittedOn)}</div>
                            </>
                    },
                    {
                        header: context.word('approved'),
                        row: (item) =>
                            <>
                                <div className="text-l text-gray-800">{item.approvedBy}</div>
                                <div className="text-xs text-gray-500">{dateFormat(item.approvedOn)}</div>
                            </>
                    },
                    {
                        header: context.word('actions'),
                        row: (item) =>
                            <>
                                <div className="flex items-center">
                                    {
                                        context.initial.superUser 
                                            ? <div>
                                                <span className="underline" onClick={event => {
                                                    event.stopPropagation()
                                                    setShowCopy(true)
                                                    setCopy({
                                                        name: item.name,
                                                        id: item.id,
                                                        userId: context.initial.userId
                                                    })
                                                }}>{context.word('copy')}</span>
                                                <span>&nbsp;|&nbsp;</span>
                                            </div>
                                            : null
                                    }
                                    <span className="underline" onClick={event => {
                                        event.stopPropagation()
                                        setShowRename(true)
                                        setRename({
                                            name: item.name,
                                            id: item.id,
                                        })
                                    }}>{context.word('edit')}</span>
                                    {context.hasPermission(PermissionEnum.ManageProgramsRemovePermission)
                                        ? <>
                                            <span>&nbsp;|&nbsp;</span>
                                            <span className="underline" onClick={event => {
                                                event.stopPropagation()
                                                rowRemoveWarning(item.id)
                                            }}>{context.word('delete')}</span>
                                        </>
                                        : null
                                    }

                                    {item.approvedOn !== null
                                        ? <span onClick={e => e.stopPropagation()}>
                                            <Download downloadExcel={downloadExcel} downloadPdf={downloadPdf}
                                                id={item.id} />
                                        </span>
                                        : null
                                    }
                                </div>
                            </>
                    }
                ]}
                keyExtractor={i => i.id}
                inputSlot={
                    showFilter ? <ProgramSearchFilter onClose={() => setShowFilter(false)} onSearch={filter => {
                        programFilter.current = filter;
                        // will trigger the call method on this search table component
                        pagedTableRef.current!.refresh();
                    }} /> : null}

                searchSlot={
                    <div className="btn bg-primary" onClick={() => setShowFilter(!showFilter)}>
                        <AdjustmentsHorizontalIcon className="w-6 h-6" />
                    </div>

                }
            />;
        </>
    }

    const ProgramHistoryArchives: React.FC<{ url: (request: PaginationRequestSearch) => Promise<PaginationResponse<ProgramHistoryResponseList>> }> = (props) => {
        const context = useContext(AppContext)
        const pagedTableRef = useRef<PagedTableFunctions<ProgramHistoryResponseList>>()
        const warningState = useWarningState<number>(-1)

        function rowRestore(id: number) {
            showSuccessOrFailed(context, ProgramController.restore({ id }), 'successfully_restored', 'failed_to_restore').then(() => {
                pagedTableRef.current?.refresh()
            })
        }

        function rowRemoveWarning(id: number) {
            warningState.show(`${context.word('restore')}?`, id)
        }

        return <>

            <Warning state={warningState} onYes={rowRestore} />

            <PagedSearchTable<ProgramHistoryResponseList>
                instantSearch={true}
                call={props.url}
                componentRef={pagedTableRef}
                columns={[
                    {
                        header: context.word('name'),
                        row: (item) => item.name
                    },
                    {
                        header: context.word('playfields'),
                        row: (item) => item.playfieldCount
                    },
                    {

                        header: context.word('created'),
                        row: (item) => (
                            <>
                                <div className="text-l text-gray-800">{item.createdBy}</div>
                                <div className="text-xs text-gray-500">{dateFormat(item.createdOn)}</div>
                            </>
                        )
                    },
                    {
                        header: context.word('updated'),
                        row: (item) => dateFormat(item.lastUpdated)
                    },
                    {
                        header: context.word('submitted'),
                        row: (item) =>
                            <>
                                <div className="text-l text-gray-800">{item.submittedTo}</div>
                                <div className="text-xs text-gray-500">{dateFormat(item.submittedOn)}</div>
                            </>
                    },
                    {
                        header: context.word('approved'),
                        row: (item) =>
                            <>
                                <div className="text-l text-gray-800">{item.approvedBy}</div>
                                <div className="text-xs text-gray-500">{dateFormat(item.approvedOn)}</div>
                            </>
                    },
                    {
                        header: context.word('actions'),
                        row: (item) =>
                            <>
                                <div className="flex items-center">
                                    {context.hasPermission(PermissionEnum.ManageProgramsRemovePermission)
                                        ? <span className="underline mx-3" onClick={event => {
                                            event.stopPropagation()
                                            rowRemoveWarning(item.id)
                                        }}>{context.word('restore')}</span>
                                        : null
                                    }
                                </div>
                            </>
                    }
                ]}
                keyExtractor={i => i.id} />
        </>
    }
