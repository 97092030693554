import React, { useContext, useMemo } from 'react'
import JsonPlayField from '../../controllers/JsonPlayField'
import ProductLookUpResponse from '../../controllers/ProductLookUpResponse'
import { conversions, summaryUnit, unitDisplay } from './units'
import ProductUnit from '../../controllers/ProductUnit'
import ElementValues, { FormatValue, getValue, makeProductObject } from './Helpers'
import AppContext from '../../appContext'
import { cellsToArray } from './Functions'
import MeasurementUnit from '../../controllers/MeasurementUnit'

const Summary: React.FC<{
    data: JsonPlayField;
    products: ProductLookUpResponse[];
}> = (props) => {
    const { initial: { system } } = useContext(AppContext)
    // convert the spares tables to jagged array
    const cells = useMemo(() => cellsToArray(props.data, system), [props])
    const context = useContext(AppContext)

    const products = useMemo(() => props.data.columns.map(c => {
        const def = props.products.find(p => p.id === c.productId)
        const unit = summaryUnit(system, conversions[c.unit].form)

        return {
            productId: c.productId,
            name: def?.nameKey !== null ? context.word(def!.nameKey) : def?.name ?? '',
            unitDisplay: conversions[unit].display
        }
    }), [props.data.columns])

    return (
        <table className="text-sm w-full border-collapse">
            <thead>

                <tr>
                    <th className="bg-gray-200 font-normal text-left relative" colSpan={2}/>
                    {products.map((column) =>
                        <th key={'name-' + column.productId} className="border-l border-r text-xs text-gray-800">
                            {column.name}
                        </th>)}
                    <th className="bg-gray-300 text-gray-600 text-center align-bottom" colSpan={6}>
                        {context.word(unitDisplay(system, ProductUnit.KgHa, ProductUnit.LbAc))}
                    </th>
                    <th className="bg-gray-400 text-gray-600 text-center align-bottom" colSpan={5}>
                        {context.word(unitDisplay(system, ProductUnit.GHa, ProductUnit.OzAc))}
                    </th>
                </tr>
                <tr>
                    <th className="bg-gray-200 text-gray-800 text-center">{context.word('phenologically')}</th>
                    <th className="bg-gray-200 text-gray-800 text-center">{context.word('month')}</th>

                    {products.map((column) =>
                        <th key={'unit-' + column.productId} className="border-l border-r text-xs text-gray-800">
                            {context.word(column.unitDisplay)}
                        </th>)}

                    <th className="align-bottom bg-gray-300">{context.word('n')}</th>
                    <th className="align-bottom bg-gray-300">{system === MeasurementUnit.METRIC ? context.word('p') : context.word('p205')}</th>
                    <th className="align-bottom bg-gray-300">{system === MeasurementUnit.METRIC ? context.word('k') : context.word('k20')}</th>
                    <th className="align-bottom bg-gray-300">{context.word('ca')}</th>
                    <th className="align-bottom bg-gray-300">{context.word('mg')}</th>
                    <th className="align-bottom bg-gray-300">{context.word('s')}</th>
                    <th className="align-bottom bg-gray-400">{context.word('fe')}</th>
                    <th className="align-bottom bg-gray-400">{context.word('zn')}</th>
                    <th className="align-bottom bg-gray-400">{context.word('b')}</th>
                    <th className="align-bottom bg-gray-400">{context.word('mn')}</th>
                    <th className="align-bottom bg-gray-400">{context.word('cu')}</th>

                </tr>
            </thead>

            <tbody>
                {props.data.rows.map((row, rowIndex) =>
                    <tr key={row.desc + row.month}>
                        <td className="border bg-white text-gray-800 whitespace-nowrap">
                            <div className="flex">
                                {row.desc}
                            </div>
                        </td>
                        <td className="border-t border-l border-b bg-white text-gray-800">
                            {row.month}
                        </td>

                        {props.data.columns.map((column, colindex) =>
                            <td key={column.productId} className="border">
                                <FormatValue value={getValue(system, cells[colindex]![rowIndex]!.standard)} decimalPlaces={1} />
                            </td>
                        )}

                        <ElementValues system={system} values={row.elements} emptyColor="text-white"/>
                    </tr>
                )}

                <tr className="font-bold">
                    <td colSpan={2} className="text-right">{context.word("Total")}</td>
                    {props.data.columns.map((column, i) =>
                        <td key={i} className="border">
                            <FormatValue value={getValue(system, column.standard)} decimalPlaces={1} />
                        </td>
                    )}

                    <ElementValues system={system} values={makeProductObject(k => props.data.elementTotals[k].standard)} emptyColor="text-white"/>
                </tr>

            </tbody>
        </table>
    )
}

export default Summary
