import PagedSearchTable, { PagedTableFunctions } from '../components/PagedSearchTable'
import React, { useContext, useRef, useState } from 'react'
import Dialog from '../components/Dialog'
import { EditRow, EditTable, focusFirstInput } from '../components/Fields'
import AppContext from '../appContext'
import AddressController from '../controllers/AddressController'
import Address from '../controllers/Address'
import AddressRequest from '../controllers/AddressRequest'
import Input from '../components/Input'
import { useValidation } from '../validation'
import { buildSetter } from '../immutableState'
import { showSuccessOrFailed } from '../Snacks'

const emptyAddress: AddressRequest = {
    addressLine: '',
    area: '',
    code: '',
    email: '',
    id: 0,
    name: '',
    number: '',
    website: ''
}

const Addresses: React.FC = () => {
    const context = useContext(AppContext)
    const pagedTableRef = useRef<PagedTableFunctions<Address>>()
    const [show, setShow] = useState(false)
    const [upsertData, setUpsertData] = useState<AddressRequest>(emptyAddress)
    const setData = buildSetter(upsertData, setUpsertData)

    function validateURL (stringVar: string): boolean {
        const holder = stringVar.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z\d@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z\d@:%_+.~#?&/=]*)/g)
        return holder !== null
    }

    function validateEmail (email: string): boolean {
        const result = String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/
            )
        return !(result?.length === undefined || result?.length === 0)
    }

    function showPopup (preset: Address | AddressRequest) {
        setUpsertData({
            id: preset.id,
            name: preset.name ?? '',
            addressLine: preset.addressLine ?? '',
            area: preset.area ?? '',
            code: preset.code ?? '',
            email: preset.email ?? '',
            number: preset.number ?? '',
            website: preset.website ?? ''
        })
        setShow(true)
    }

    function upsertCallback (data: AddressRequest) {
        if (!validation.validate()) { return }
        showSuccessOrFailed(context, AddressController.upsert(data)).then(() => {
            pagedTableRef.current?.refresh()
            setShow(false)
        })
    }

    const validation = useValidation({
        nameRequired: () => upsertData.name.length > 0,
        addressRequired: () => upsertData.addressLine.length > 0,
        areaRequired: () => upsertData.area.length > 0,
        codeRequired: () => upsertData.code.length > 0,
        numberRequired: () => upsertData.number.length > 0,
        emailRequired: () => validateEmail(upsertData.email),
        websiteRequired: () => validateURL(upsertData.website)
    })

    return (
        <>
            <Dialog mounted={focusFirstInput} title={upsertData.id === 0 ? context.word('new_address') : context.word('edit_address')} show={show} setShow={setShow}
                body={
                    <EditTable save={() => upsertCallback(upsertData)} discard={() => setShow(false)} saveWord={upsertData.id === 0 ? 'insert' : 'update'}>
                        {EditRow(
                            context.word('name'),
                            <Input value={upsertData.name} change={v => setData({ name: v })} />,
                            validation.rules.nameRequired,
                            context.word('name_required')
                        )}
                        {EditRow(
                            context.word('address'),
                            <Input value={upsertData.addressLine} change={v => setData({ addressLine: v })} />,
                            validation.rules.addressRequired,
                            context.word('address_required')
                        )}
                        {EditRow(
                            context.word('area'),
                            <Input value={upsertData.area} change={v => setData({ area: v })} />,
                            validation.rules.areaRequired,
                            context.word('area_required')
                        )}
                        {EditRow(
                            context.word('postal_code'),
                            <Input value={upsertData.code} change={v => setData({ code: v })} />,
                            validation.rules.codeRequired,
                            context.word('code_required')
                        )}
                        {EditRow(
                            context.word('number'),
                            <Input value={upsertData.number} change={v => setData({ number: v })} />,
                            validation.rules.numberRequired,
                            context.word('number_required')
                        )}
                        {EditRow(
                            context.word('email'),
                            <Input value={upsertData.email} change={v => setData({ email: v })} />,
                            validation.rules.emailRequired,
                            context.word('email_required')
                        )}
                        {EditRow(
                            context.word('website'),
                            <Input value={upsertData.website} change={v => setData({ website: v })} />,
                            validation.rules.websiteRequired,
                            context.word('website_required')
                        )}
                    </EditTable>
                }/>

            <div className="btn bg-primary m-2" onClick={() => showPopup(emptyAddress)}>{context.word('add_address')}</div>

            <PagedSearchTable<Address>
                componentRef={pagedTableRef}
                call={AddressController.paged}
                columns={[
                    { header: context.word('name'), row: item => item.name },
                    {
                        header: context.word('address'),
                        row: item => <div>
                            <div>{item.addressLine}</div>
                            <div>{item.area}</div>
                            <div>{item.code}</div>
                        </div>
                    },
                    { header: context.word('number'), row: item => item.number },
                    { header: context.word('email'), row: item => item.email },
                    { header: context.word('website'), row: item => item.website },
                    {
                        header: context.word('action'),
                        row: item => <div>
                            <span className="underline cursor-pointer" onClick={() => showPopup(item)}>{context.word('edit')}</span>
                        </div>
                    }
                ]}
                keyExtractor={i => i.id}/>

        </>
    )
}

export default Addresses
