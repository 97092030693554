import React, { Dispatch, useContext, useEffect, useRef, useState } from 'react'
import AppContext from '../../appContext'
import { defaultUnit, unitDisplay, UnitOptions } from './units'
import { PlayFieldState, PlayFieldStateAction } from './playFieldState'
import ProductLookUpResponse from '../../controllers/ProductLookUpResponse'
import Dialog from '../../components/Dialog'
import ProductLookup from './ProductLookup'
import ProductUnit from '../../controllers/ProductUnit'
import { classNames } from '../../wrapper'
import { borderStyle, CellPosition, getCellSafe, productToFrom } from './Functions'
import MeasurementUnit from '../../controllers/MeasurementUnit'
import Warning, { useWarningState } from './Warning'
import { arrayPush } from '../../immutableState'
import useDrag from './drag'




function getNewPosition (cell: CellPosition, event: KeyboardEvent, rows: number, cols: number): CellPosition {
    switch (event.key) {
    case 'ArrowUp':
        return { x: cell.x, y: cell.y > 0 ? cell.y - 1 : rows - 1 }
    case 'ArrowDown':
    case 'Enter':
        return { x: cell.x, y: cell.y < rows - 1 ? cell.y + 1 : 0 }
    case 'ArrowLeft':
        return { x: cell.x > 0 ? cell.x - 1 : cols - 1, y: cell.y }
    case 'ArrowRight':
        return { x: cell.x < cols - 1 ? cell.x + 1 : 0, y: cell.y }
    }
    return cell
}

export function usePlayFieldProduct (playFieldDispatch: Dispatch<PlayFieldStateAction>) {
    const [products, setProducts] = useState<ProductLookUpResponse[]>([])
    const context = useContext(AppContext)

    function addProductIfNotExists (add: ProductLookUpResponse) {
        if (products.findIndex(p => p.id === add.id) === -1) { setProducts(arrayPush(products, add)) }
    }

    function addProduct (product: ProductLookUpResponse) {
        addProductIfNotExists(product)
        playFieldDispatch({
            type: 'addColumn',
            product,
            unit: defaultUnit(context.initial.system, product.solid),
            color: '',
            system: context.initial.system
        })
    }

    function editProduct (column: number, product: ProductLookUpResponse) {
        addProductIfNotExists(product)
        playFieldDispatch({
            type: 'updateColumn',
            index: column,
            product
        })
    }

    return {
        addProduct,
        editProduct,
        products,
        setProducts
    }
}

const PlayFieldTemplate: React.FC<{
    state: [PlayFieldState, Dispatch<PlayFieldStateAction>];
    products: ProductLookUpResponse[];
    addProduct: (product: ProductLookUpResponse, applyToAll: boolean) => void;
    editProduct: (columnIndex: number, product: ProductLookUpResponse, applyToAll: boolean) => void;
    // render options to control template / full mode
    render?: {
        extractions: (rowIndex: number) => React.ReactNode;
        totals: () => React.ReactNode;
        viewBlock: () => React.ReactNode;
    }
    tableGrapes: boolean
}> = (props) => {
    const { initial: { system } } = useContext(AppContext)
    const [showProduct, setShowProduct] = useState(false)
    const [state, stateDispatch] = props.state
    const [selectedCell, setSelectedCell] = useState<CellPosition | null>(null)
    const productEditIndex = useRef<number | null>(null)
    const context = useContext(AppContext)
    const confirmation = useWarningState<number>(0)
    const [highlight, setHighlight] = useState(false)
    const [onOver, setOnOver] = useState<number>()
    const [initialIndex, setInitialIndex] = useState<number>(0)
    const [firstPlace, setFirstPlace] = useState<boolean>(false)
    const [moving, setMoving] = useState(false)
    const [regionId, setRegionId] = useState<number | null>(context.initial.regions[0] ?? null)


    function addProduct () {
        productEditIndex.current = null
        setShowProduct(true)
    }

    function editProduct (columnIndex: number) {
        productEditIndex.current = columnIndex
        setShowProduct(true)
    }

    function productSelected (product: ProductLookUpResponse, applyToAll: boolean) {
        setShowProduct(false)

        if (productEditIndex.current === null) {
            props.addProduct(product, applyToAll)
            return
        }

        props.editProduct(productEditIndex.current, product, applyToAll)
    }

    function getCellValue (row: number, column: number) {
        return getCellSafe(state.data.cells, row, column).value
    }

    function handleKeyDown (event: KeyboardEvent) {
        // we don't have access to selectedCell directly because it was created with initial state
        // see: https://stackoverflow.com/questions/55265255/react-usestate-hook-event-handler-using-initial-state
        const d = props.state[0].data
        const inputs = Array.prototype.slice.call(document.querySelectorAll<HTMLInputElement>('.playfield-input'))
        const index = inputs.findIndex(f => f === document.activeElement)
        if (index === -1) { return }

        // determine x, y for current focused element
        const current = { y: Math.trunc(index / d.columns.length), x: index % d.columns.length }
        const newValue = getNewPosition(current, event, d.rows.length, d.columns.length)
        // convert newValue back to index
        const newIndex = newValue.y * d.columns.length + newValue.x

        if (current.x !== newValue.x || current.y !== newValue.y) {
            inputs[newIndex]!.focus()
            inputs[newIndex]!.select()
            event.preventDefault()
        }
    }

    // attach keydown event with props.state in scope
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [props.state])

    const fullMode = props.render !== undefined
    const [color, setColor] = useState('#EDF2F7')

    function focusBlurCell (state: 'focus' | 'blur', col: number, row: number) {
        if (state === 'blur') {
            setSelectedCell(null)
            stateDispatch({ type: 'blurCellValue', row, column: col })
        }
        if (state === 'focus') {
            setSelectedCell({ x: col, y: row })
        }
    }

    function columnRemoveWarning (columnIndex: number) {
        const name = state.data.columns[columnIndex] === null ? '' : productName(state.data.columns[columnIndex]?.product!)

        confirmation.show(`${context.word('removing')} ${name}?`, columnIndex)
    }

    function removeColumn (columnIndex: number) {
        stateDispatch({
            type: 'removeColumn',
            index: columnIndex
        })
        confirmation.hide()
    }

    function productName (product: ProductLookUpResponse) {
        return product.nameKey !== null ? context.word(product.nameKey) : product.name
    }

    function onDragStart () {
        setMoving(true)
    }

    function onDragLeave () {
        setHighlight(false)
    }
    
    
    //Return the common regions of the products 
    const allRegions =  state.data.columns.map(c => c.product.regionsKeys)
    
    const common = allRegions.reduce<string[]>((acc, current, index) => {
        if (index == 0)
            return current;
        
        if (acc.length == 0)
            return [];
        
        return acc.filter(a => current.includes(a));
    }, []);
   
     
    

    function onDragOver (event: React.DragEvent<HTMLDivElement>, index: number) {
        setOnOver(index)
        setHighlight(true)
        setFirstPlace(false)
        event.preventDefault()
        event.stopPropagation()
    }

    const drag = useDrag<number>('drag-product', (target, destination) => stateDispatch({
        type: 'moveColumn',
        from: target,
        to: destination
    }))

    return (
        <div>
            <Dialog show={showProduct} setShow={setShowProduct} title={context.word('choose_product')}
                body={<ProductLookup 
                    productSelected={productSelected}
                    selectedRegionId={regionId}
                    setSelectedRegionId={setRegionId}
                />}/>

            <Warning state={confirmation} onYes={removeColumn}/>

            <div className="bg-white overflow-y-auto">
                <table className="text-sm w-full border-collapse">
                    <thead>
                    {common.length <= 0 && state.data.columns.length > 0 ? <tr>
                        <th colSpan={2}></th>
                        <th className='text-xs text-red-500' colSpan={state.data.columns.length}>{context.word('Warning: products across regions')}</th>
                    </tr> : null}
                        <tr>
                            <th className="bg-gray-200 font-normal text-left relative" colSpan={2}>
                                {props.render?.viewBlock()}
                            </th>

                            {state.data.columns.map((column, columnIndex) =>
                                <th  key={column.key}
                                    {...drag.targetAndDest(columnIndex)}
                                    // draggable={!props.tableGrapes}
                                    className={classNames('border-l border-r rotate text-xs  text-gray-800 truncate',
                                        // drag. ? 'border-l-4 border-l-gray-500' : '',
                                        drag.currentDrag !== null && drag.isOver(columnIndex) && drag.currentDrag < columnIndex ? 'border-r-4 border-gray-500' : '',
                                        drag.currentDrag !== null && drag.isOver(columnIndex) && drag.currentDrag > columnIndex ? 'border-l-4 border-gray-500' : '',
                                        drag.currentDrag === columnIndex ? 'opacity-50' : '')}
                                    title= {
                                        (context.word("sg_value") + ": "  + column.product.sg as any as string ?? '')
                                         + ' \n ('+column.product.regionsKeys.map(r => context.word(r)).join(', ')+')'
                                    }
                                    style={{ backgroundColor: column.hexColor ? column.hexColor : '#EDF2F7' }}>
                                    <div>
                                        <span>{productName(column.product)}</span>
                                    </div>
                                </th>
                            )}

                            <th className={classNames('text-gray-600 text-center align-bottom', fullMode ? 'bg-gray-300' : 'bg-white')} colSpan={6}>
                                {/*{*/}
                                {/*    props.tableGrapes*/}
                                {/*        ? null*/}
                                {/*        : */}
                                        <div
                                            onClick={() => addProduct()}
                                            className="float-left w-8 h-8 text-xl rounded-full text-white text-center cursor-pointer bg-primary-500 shadow hover:bg-primary-600">+
                                        </div>
                                {/*}*/}

                                {fullMode ?
                                    <span>{unitDisplay(system, ProductUnit.KgHa, ProductUnit.LbAc)}</span> : null}
                            </th>

                            {fullMode
                                ? <th className="bg-gray-400 text-gray-600 text-center align-bottom" colSpan={5}>{unitDisplay(system, ProductUnit.GHa, ProductUnit.OzAc)}</th>
                                : null
                            }
                        </tr>

                        <tr>
                            <th className="bg-gray-200 text-gray-800 text-center">{context.word('phenologically')}</th>
                            <th className="bg-gray-200 text-gray-800 text-center">{context.word('month')}</th>

                            {state.data.columns.map((column, columnIndex) =>
                                <th key={column.key} className="border-l border-r text-xs text-gray-800">
                                    <div>
                                        <input className='bg-white' 
                                               type="color" 
                                               list="presetColors" 
                                               defaultValue={column.hexColor ? column.hexColor : '#EDF2F7'}
                                            onClick={e => {
                                                e.currentTarget.value = color
                                            }}
                                            onChange={e => {
                                                stateDispatch({
                                                    type: 'updateColumn',
                                                    index: columnIndex,
                                                    color: (e.target as HTMLInputElement).value
                                                })
                                            }}
                                            onBlur={e => {
                                                stateDispatch({
                                                    type: 'updateColumn',
                                                    index: columnIndex,
                                                    color: (e.target as HTMLInputElement).value
                                                })
                                                setColor((e.target as HTMLInputElement).value)
                                            }}
                                        />
                                        <datalist id="presetColors">
                                            <option value="#cbaf97"/>
                                            <option value="#93d9f0"/>
                                            <option value="#8abe3d"/>
                                            <option value="#a16207"/>
                                            <option value="#c084fc"/>
                                            <option value="#EDF2F7"/>
                                        </datalist>
                                    </div>
                                    {/*{*/}
                                    {/*    props.tableGrapes*/}
                                    {/*        ? null*/}
                                    {/*        : */}
                                            <div>
                                                <div
                                                    className="inline-block align-middle text-green-500 mx-1 cursor-pointer"
                                                    onClick={() => editProduct(columnIndex)}>{context.word('edit')}</div>
                                                <div onClick={() => columnRemoveWarning(columnIndex)}
                                                     className="inline-block align-middle text-red-500 mx-1 cursor-pointer">X
                                                </div>
                                            </div>
                                    {/*}*/}

                                    {/*{*/}
                                    {/*    props.tableGrapes*/}
                                    {/*        ? <div>*/}
                                    {/*            {*/}
                                    {/*                UnitOptions[system][productToFrom(column.product)]*/}
                                    {/*                    .find(s => s.unit == (system === MeasurementUnit.METRIC ? column.unit : column.imperialUnit))*/}
                                    {/*                    ?.display*/}
                                    {/*            }*/}
                                    {/*        </div>*/}
                                    {/*        : */}
                                            <select
                                                value={system === MeasurementUnit.METRIC ? column.unit : column.imperialUnit}
                                                className="outline-none"
                                                onInput={e => {
                                                    stateDispatch({
                                                        type: 'updateColumn',
                                                        index: columnIndex,
                                                        unit: {
                                                            system,
                                                            value: (e.target as HTMLInputElement).value as ProductUnit
                                                        }
                                                    })
                                                }}>

                                                {
                                                    UnitOptions[system][productToFrom(column.product)]
                                                        .filter(unitOption => unitOption.unit !== 'Gallon' && unitOption.unit !== 'Lb' && unitOption.unit !== 'Liter' && unitOption.unit !== 'Kg')
                                                        .map(o => (<option key={o.unit}
                                                                           value={o.unit}>{context.word(o.display)}</option>))
                                                }
                                            </select>
                                    {/*}*/}

                                </th>)}

                            {fullMode
                                ? <>
                                    <th className="align-bottom bg-gray-300">{context.word('n')}</th>
                                    <th className="align-bottom bg-gray-300">{system === MeasurementUnit.METRIC ? context.word('p') : context.word('p205')}</th>
                                    <th className="align-bottom bg-gray-300">{system === MeasurementUnit.METRIC ? context.word('k') : context.word('k20')}</th>
                                    <th className="align-bottom bg-gray-300">{context.word('ca')}</th>
                                    <th className="align-bottom bg-gray-300">{context.word('mg')}</th>
                                    <th className="align-bottom bg-gray-300">{context.word('s')}</th>

                                    <th className="align-bottom bg-gray-400">{context.word('fe')}</th>
                                    <th className="align-bottom bg-gray-400">{context.word('zn')}</th>
                                    <th className="align-bottom bg-gray-400">{context.word('b')}</th>
                                    <th className="align-bottom bg-gray-400">{context.word('mn')}</th>
                                    <th className="align-bottom bg-gray-400">{context.word('cu')}</th>
                                </>
                                : <th></th>}

                        </tr>
                    </thead>

                    <tbody>

                        <MemoRows
                            state={[state, stateDispatch]}
                            extractions={props.render?.extractions}
                            focusBlurCell={focusBlurCell}
                            getCellValue={getCellValue}
                            selectedCell={selectedCell}
                            tableGrapes={props.tableGrapes}
                        />

                        {props.render?.totals()}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

// from excel rows by \n, columns by \t
function formatClipboard (clipboard: string): string[][] {
    return clipboard.split('\n').map(r => r.split('\t'))
}

const Rows: React.FC<{
    state: [PlayFieldState, Dispatch<PlayFieldStateAction>];
    extractions?: (rowIndex: number) => React.ReactNode;
    selectedCell: CellPosition | null;

    getCellValue: (row: number, col: number) => string;
    focusBlurCell: (state: 'focus' | 'blur', col: number, row: number) => void;
    tableGrapes: boolean
}> = (props) => {
    const [state, stateDispatch] = props.state
    const context = useContext(AppContext)
    const confirmation = useWarningState<number>(-1)

    function removeRow (rowIndex: number) {
        const name = state.data.rows[rowIndex]?.desc
        confirmation.show(`${context.word('sure_delete')} ${name}?`, rowIndex)
    }

    function removeRowConfirmed (rowIndex: number) {
        stateDispatch({
            type: 'removeRow',
            index: rowIndex
        })
    }

    function handlePaste (event: React.ClipboardEvent<HTMLInputElement>, column: number, row: number) {
        const text = event.clipboardData.getData('text')
        if (!text || text === '') { return }

        const data = formatClipboard(text).map(t => t.map(t => t.trim().replace(/(\r\n|\n|\r)/gm, "")))
        stateDispatch({ type: 'paste', data, column, row })
        event.preventDefault()
    }

    return <>
        <Warning state={confirmation} onYes={removeRowConfirmed}/>
        {state.data.rows.map((row, rowIndex) =>
            <tr key={row.index}>
                <td className="border bg-white text-gray-800 whitespace-nowrap">
                    {/*{*/}
                    {/*    props.tableGrapes*/}
                    {/*        ? null*/}
                    {/*        : */}
                            <div onClick={() => removeRow(rowIndex)}
                                   className="text-red-500 mx-1 cursor-pointer inline-block">X
                            </div>
                    {/*}*/}
                    <input
                        // disabled={props.tableGrapes}
                        className="flex-1 outline-none cursor-pointer bg-white w-full"
                        onPaste={e => handlePaste(e, -2, rowIndex)}
                        onFocus={event => event.target.select()}
                        value={row.desc}
                        onChange={e => stateDispatch({
                            type: 'updateRow',
                            index: rowIndex,
                            desc: e.target.value
                        })}/>
                </td>
                <td className="border-t border-l border-b bg-white text-gray-800">

                    <input className="w-full outline-none cursor-pointer bg-white"
                           // disabled={props.tableGrapes}
                        value={row.month}
                        onPaste={e => handlePaste(e, -1, rowIndex)}
                        onFocus={event => event.target.select()}
                        onChange={e => stateDispatch({
                            type: 'updateRow',
                            index: rowIndex,
                            month: e.target.value
                        })
                        }/>
                </td>
                {state.data.columns.map((column, colindex) =>
                    <td key={column.key}
                        className={classNames(borderStyle({
                            x: colindex,
                            y: rowIndex
                        }, props.selectedCell))}>
                        <input type="text" className="playfield-input text-xs relative outline-none w-full"
                            onPaste={e => handlePaste(e, colindex, rowIndex)}
                            value={props.getCellValue(rowIndex, colindex)}
                            onInput={e => stateDispatch({
                                type: 'setCellValue',
                                value: (e.target as HTMLInputElement).value,
                                row: rowIndex,
                                column: colindex
                            })}
                            onFocus={(event) => {
                                props.focusBlurCell('focus', colindex, rowIndex)
                                event.target.select()
                            }}
                            onBlur={() => props.focusBlurCell('blur', colindex, rowIndex)}
                        />

                        {/* <div className="text-xs text-gray-500 bg-white"><FormatValue value={state.getCellStandard(rowIndex, colindex).metric}/></div> */}
                        {/* <div className="text-xs text-gray-500 bg-white"><FormatValue value={state.getCellStandard(rowIndex, colindex).imperial}/> Lb/Ac</div> */}
                    </td>
                )}

                {props.extractions ? props.extractions(rowIndex) : <td></td>}

            </tr>
        )}
        <tr>
            <td colSpan={2}>
                {/*{*/}
                {/*    props.tableGrapes*/}
                {/*        ? null*/}
                {/*        : */}
                        <div onClick={() => stateDispatch({
                            type: 'addRow',
                            desc: '',
                            month: ''
                        })}
                               className="w-8 h-8 text-xl rounded-full text-white text-center cursor-pointer bg-primary-500 shadow hover:bg-primary-600">+
                        </div>
                {/*}*/}
            </td>
            <td colSpan={99}/>
        </tr>
    </>
}

const MemoRows = React.memo(Rows, (prevProps, nextProps) => {
    const prev = prevProps.state[0].data
    const next = nextProps.state[0].data

    return prevProps.selectedCell === nextProps.selectedCell && prev.rows === next.rows && prev.cells === next.cells
    // && arrayCompare(prev.columns, next.columns, (a, b) => a.product == b.product && a.unit == b.unit)
})

export default PlayFieldTemplate
