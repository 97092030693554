import React, {PropsWithChildren, useContext, useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Login from './Login'
import App from './App'
import AppContextProvider from './AppContextProvider'
import AuthController from './controllers/AuthController'
import {
    BrowserRouter,
    createBrowserRouter, createRoutesFromElements,
    Navigate,
    Outlet,
    Route, RouterProvider,
    Routes,
    useLocation,
    useParams
} from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { BrowserTracing } from '@sentry/tracing'

import Clients from './pages/Clients'
import Farms from './pages/Farms'
import Programs from './pages/Programs'
import Approvals from './pages/Approvals'
import Agents from './pages/Agents'
import Distributors from './pages/Distributors'
import Templates from './pages/Templates'
import Categories from './pages/Categories'
import Products from './pages/Products'
import Regions from './pages/Regions'
import Language from './pages/Language'
import Crops from './pages/Crops'
import Cultivars from './pages/Cultivars'

import Addresses from './pages/Addresses'
import Roles from './pages/Roles'
import Companies from './pages/Companies'
import Users from './pages/Users'
import Errors from './pages/Errors'
import Program from './pages/program/Program'
import AppState from './controllers/AppState'
import PermissionEnum from './controllers/PermissionEnum'
import NewProgram from './pages/program/NewProgram'
import EditTemplate from './pages/EditTemplate'
import Approval from './pages/Approval'
import ProgramController from './controllers/ProgramController'
import PlayFieldResponse from './controllers/PlayFieldResponse'
import ResetPassword from './ResetPassword'
import { switcher } from './wrapper'
import AppContext from './appContext'
import Dialog from './components/Dialog'
import ProgramApprove from './pages/program/ProgramApprove'
import { mutate } from './immutableState'
import {showSuccessOrFailed} from "./Snacks";
import { isLocalhost } from './controllers/helper'
import {createRoot} from "react-dom/client";

function initializeSentry (userName: string, version: string) {
    if (isLocalhost()) {
        return
    }
    Sentry.init({
        dsn: 'https://91d5d4072ae34ec6858aa8db64288350@o998114.ingest.sentry.io/6581311',
        integrations: [Sentry.browserTracingIntegration()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
    })

    Sentry.setUser({
        username: userName
    })
    Sentry.setTag("version", version)
}

const ProgramRoute: React.FC = () => {
    const app = useContext(AppContext)
    const params = useParams()
    const [data, setData] = useState<PlayFieldResponse | null>(null)
    const id = parseInt(params.programId as string)

    const [showApprover, setShowApprover] = useState(false)

    function submitProgram(approver: number) {
        showSuccessOrFailed(app, ProgramController.submit({
            id,
            approverId: approver
        }))
        .then(() => {
            setShowApprover(false)

            if (!data) {
                return
            }

            setData(mutate(data, d => {
                d.program.readyForApproval = true
                d.program.approved = false
            }))
        })
    }

    useEffect(() => {
        if (isNaN(id)) { return }
        ProgramController.get({ id }).then(data => {
            setData(data)
        })
    }, [])

    if (data === null) {
        return <div>Loading...</div>
    }

    const footer = switcher(data, c => c
        .case(p => !p.program.readyForApproval, () => <div className="btn bg-primary m-2"
            onClick={() => {
                setShowApprover(true)
            }}>{app.word('submit_approval')}</div>)
        .case(p => !p.program.approved, () => <div className="btn bg-gray-500 m-2">{app.word('waiting_approval')}</div>)
    )

    function resetApprovals () {
        if (!data) { return }

        setData(mutate(data, d => {
            d.program.readyForApproval = false
            d.program.approved = false
        }))
    }

    return <>
        <Dialog
            title={app.word('select_approver')}
            body={<ProgramApprove onCancel={() => setShowApprover(false)} onSubmit={submitProgram}/>}
            show={showApprover} setShow={setShowApprover}/>

        <Program id={id} data={data} footer={footer} onSave={() => resetApprovals()}/>
    </>
}

const ErrorPage: React.FC = () => {
    return (
        <div className="h-screen w-full flex">
            <div className="m-auto">
                <img className="w-40 md:w-full" src="logo.png" alt=""/>

                <h1 className="my-2 text-gray-800 font-bold text-2xl">
                    Oops, something went wrong. 
                </h1>
                <div className="my-2 text-gray-800">The exact error has been logged and our developers have been notified.</div>
                <a href="/"
                    className="cursor-pointer poin inline-block border rounded md py-4 px-8 text-center bg-primary-600 text-white hover:bg-primary-70">
                    Start over
                </a>
            </div>
        </div>
    )
}

const InitialApp: React.FC<{ state: AppState }> = (props) => {
    const [initial, setInitial] = useState(props.state)

    function renderPermission (permission: PermissionEnum, node: React.ReactNode): React.ReactNode {
        if (initial.superUser || initial.permissions.find(s => s === permission) !== null) { return node }

        return null
    }

    initializeSentry(initial.userDisplay, initial.version)
    
    const router = createBrowserRouter(createRoutesFromElements(
        <>
            <Route path="/login" element={<Login setInitial={setInitial}/>}/>
            <Route path="/token" element={<ResetPassword setInitial={setInitial}/>}/>
            <Route path="/"
                   element={<RequireAuth appState={initial}><App setInitial={setInitial}/></RequireAuth>}>
                {renderPermission(PermissionEnum.ManagementClientsPermission, <Route path="clients"
                                                                                     element={<Clients/>}/>)}
                {renderPermission(PermissionEnum.ManagementFarmsPermission, <Route path="farms"
                                                                                   element={<Farms/>}/>)}
                {renderPermission(PermissionEnum.ManagementProgramsPermission, <Route path="programs"
                                                                                      element={<Programs/>}/>)}

                {renderPermission(PermissionEnum.ManagementProgramsPermission, <Route path="new-program"
                                                                                      element={<NewProgram/>}/>)}

                {renderPermission(PermissionEnum.ManagementProgramsPermission, <Route path="program/:programId"
                                                                                      element={<ProgramRoute/>}/>)}

                {renderPermission(PermissionEnum.ManagementApprovalsPermission, <Route path="approvals"
                                                                                       element={<Approvals/>}/>)}

                {renderPermission(PermissionEnum.ManagementApprovalsPermission, <Route path="approval/:programId"
                                                                                       element={<Approval/>}/>)}

                {renderPermission(PermissionEnum.ManagementAgentPermission, <Route path="agents"
                                                                                   element={<Agents/>}/>)}
                {renderPermission(PermissionEnum.ManagementDistributorPermission, <Route path="distributors"
                                                                                         element={
                                                                                             <Distributors/>}/>)}

                <Route path="/templates" element={renderPermission(PermissionEnum.ManagementTemplatesPermission, <Outlet/>)}>
                    <Route path="" element={<Templates/>}/>
                    <Route path=":templateId" element={<EditTemplate/>}/>
                </Route>

                {renderPermission(PermissionEnum.ManagementCategoryPermission, <Route path="categories"
                                                                                      element={
                                                                                          <Categories/>}/>)}
                {renderPermission(PermissionEnum.ManagementProductsPermission, <Route path="products"
                                                                                      element={<Products/>}/>)}
                {renderPermission(PermissionEnum.ManagementRegions, <Route path="regions"
                                                                           element={<Regions/>}/>)}
                {renderPermission(PermissionEnum.ManagementLanguagePermission, <Route path="languages"
                                                                                      element={<Language/>}/>)}
                {renderPermission(PermissionEnum.ManagementCropPermission, <Route path="crops"
                                                                                  element={<Crops/>}/>)}
                {renderPermission(PermissionEnum.ManagementCultivarPermission, <Route path="cultivars"
                                                                                      element={<Cultivars/>}/>)}
                {renderPermission(PermissionEnum.ManagementAddress, <Route path="addresses"
                                                                           element={<Addresses/>}/>)}
                {renderPermission(PermissionEnum.ManagementRolesPermissions, <Route path="roles"
                                                                                    element={<Roles/>}/>)}
                {renderPermission(PermissionEnum.ManagementCompanyPermission, <Route path="companies"
                                                                                     element={<Companies/>}/>)}
                {renderPermission(PermissionEnum.ManagementUsersPermission, <Route path="users"
                                                                                   element={<Users/>}/>)}
                {renderPermission(PermissionEnum.ManageErrors, <Route path="errors" element={<Errors/>}/>)}
            </Route>
            <Route path="*" element={<Navigate to="/"/>}/>
        </>
    ));    
    
    return (
        <Sentry.ErrorBoundary fallback={<ErrorPage/>}>
            <AppContextProvider initial={initial}>
                <RouterProvider router={router}/>
            </AppContextProvider>
        </Sentry.ErrorBoundary>)
}

AuthController.getAppState().then(data => {
    if (window.localStorage.getItem('version') != data.version) {
        window.localStorage.setItem('version', data.version)
        window.location.reload()
    }
    
    createRoot(document.getElementById('root')!).render(
        <React.StrictMode>
            <InitialApp state={data}/>
        </React.StrictMode>
    )
}).catch(err => {
    console.log(err)
    document.getElementById('loader')!.innerHTML = 'Error loading app'
})

const RequireAuth: React.FC<PropsWithChildren<{ appState: AppState }>> = (props) => {
    const location = useLocation()

    if (!props.appState.loggedIn) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace/>
    }

    return <>{props.children}</>
}
