import React, { useContext, useRef, useState } from 'react'
import ClientController from '../../controllers/ClientController'
import PagedSearchTable, { PagedTableFunctions } from '../../components/PagedSearchTable'
import Stepper, { StepperRef } from '../../components/Stepper'
import { bind, onEnter, wrapLoader } from '../../wrapper'
import AppContext from '../../appContext'
import FarmController from '../../controllers/FarmController'
import ProgramController from '../../controllers/ProgramController'
import PlayfieldTemplate, { usePlayFieldProduct } from './PlayfieldTemplate'
import { emptyConvertSize } from './Functions'
import { buildSetter } from '../../immutableState'
import {
    buildPlayFieldState,
    emptyPlayFieldState, getExtraction,
    stateToPlayField,
    usePlayFieldState
} from './playFieldState'
import SetupLandFields from './SetupLandFields'
import ProgramLandSetup from '../../controllers/ProgramLandSetup'
import JsonPlayField from '../../controllers/JsonPlayField'
import CropType from '../../controllers/CropType'
import { useNavigate } from 'react-router-dom'
import ChooseTemplate from '../ChooseTemplate'
import PlayFieldTemplateResponse from '../../controllers/PlayFieldTemplateResponse'
import { EditRow, EditTable, focusFirstInput } from '../../components/Fields'
import Input from '../../components/Input'
import Dialog from '../../components/Dialog'
import { showSuccessOrFailed } from '../../Snacks'
import { useValidation } from '../../validation'
import ClientAddFarmRequest from '../../controllers/ClientAddFarmRequest'
import ClientPaged from '../../controllers/ClientPaged'
import ElementValues from "./Helpers";

const emptyClientAndFarm: ClientAddFarmRequest = {
    clientName: '',
    farmName: ''
}

const NewProgram: React.FC = () => {
    const pagedTableRef = useRef<PagedTableFunctions<ClientPaged>>()
    const stepperRef = useRef<StepperRef>(null)
    const context = useContext(AppContext)
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const [upsertData, setUpsertData] = useState<ClientAddFarmRequest>(emptyClientAndFarm)
    const setData = buildSetter(upsertData, setUpsertData)

    const [name, setName] = useState('')

    const [client, setClient] = useState<ClientPaged | null>(null)
    const [lands, setLands] = useState<ProgramLandSetup[]>([])

    const [cropType, setCropType] = useState<CropType>(CropType.Annual)

    function clientClicked (row: ClientPaged) {
        setCropType(row.lastCropType)
        wrapLoader(context, ProgramController.lands(row.farmId), (lands) => {
            setLands(lands)
            setClient(row)
            //  go the the fields & blocks tab
            stepperRef.current?.next()
        })
    }

    function generateProgram (data: JsonPlayField) {
        if (client === null) { return }

        const hideLoader = context.showLoader()
        ProgramController.generate({
            farmId: client.farmId,
            clientId: client.clientId,
            name,
            lands,
            playField: data,
            lastCropType: cropType
        }).then(resp => {
            // go to the newly created program
            navigate(`/program/${resp}`)
        }).finally(hideLoader)
    }

    function nextStep () {
        stepperRef.current?.next()
        pagedTableRef.current?.focus()
    }

    function showUpsert (data: ClientAddFarmRequest) {
        setUpsertData(data)
        setShow(true)
    }

    const validation = useValidation({
        nameRequired: () => upsertData.clientName.length > 0
    })

    function upsert (data: ClientAddFarmRequest) {
        if (!validation.validate()) { return }
        showSuccessOrFailed(context, ClientController.addWFarm({
            clientName: data.clientName,
            farmName: data.farmName
        })).then(() => {
            setShow(false)
            pagedTableRef.current?.refresh()
        })
    }

    const nameInput = useRef<HTMLInputElement>(null)

    return <div>

        <Dialog mounted={focusFirstInput} title={context.word('local_client_addition')} show={show} setShow={setShow}
            body={
                <EditTable save={() => upsert(upsertData)} discard={() => setShow(false)}
                    saveWord={'insert'}>
                    {EditRow(
                        context.word('clientName'),
                        <Input value={upsertData.clientName} change={v => setData({ clientName: v })} />,
                        validation.rules.nameRequired,
                        context.word('clientName_required')
                    )}
                    {EditRow(
                        context.word('farmName'),
                        <Input value={upsertData.farmName} change={v => setData({ farmName: v })} />
                    )}
                </EditTable>
            }
        />

        <div className="text-primary text-xl my-2 underline">{context.word('create_a_new_program')}.</div>
        <Stepper
            tabMode="toggle"
            ref={stepperRef}
            onChange={(_, toIndex, source) => {
                // cannot go the template and playField tab directly
                // only allowed through calling .net

                return !(toIndex === 3 && source === 'click')
            }}
            tabs={[
                {
                    key: 'name',
                    text: context.word('program_name'),
                    onFocus: () => {
                        nameInput.current?.focus()
                    },
                    content: () => <div className="p-2">
                        <div className="text-xl underline tracking-wider">1. {context.word('program_name')}</div>
                        <input ref={nameInput} className="input" type="text" autoFocus={true}
                            placeholder={context.word('program_name')} {...bind(name, setName)}
                            onKeyDown={onEnter(nextStep)}/>
                        <div className="text-right my-2">
                            <div className="btn bg-primary" onClick={() => stepperRef.current?.next()}>{context.word('next')}</div>
                        </div>
                    </div>
                },
                {
                    key: 'clientfarm',
                    text: context.word('client_and_farm'),
                    onFocus: () => {
                        pagedTableRef.current?.focus()
                    },
                    content: () => <div className="p-2">
                        <div className="text-xl underline tracking-wider">2. {context.word('client_and_farm')}</div>
                        <div className="btn bg-primary m-2" onClick={() => showUpsert(emptyClientAndFarm)}>{context.word('add_client_and_farm')}</div>
                        <div>{client ? context.word('you_have_selected') + client.clientName : context.word('choose_a_client')}</div>
                        <PagedSearchTable
                            componentRef={pagedTableRef}
                            call={ClientController.search}
                            rowClick={row => clientClicked(row)}
                            columns={[
                                {
                                    header: context.word('client_name'),
                                    row: (item) => item.clientName
                                },
                                {
                                    header: context.word('farm_name'),
                                    row: (item) => item.farmName
                                },
                                {
                                    header: context.word('originate'),
                                    row: (item) => item.originate.toString()
                                }
                            ]}
                            keyExtractor={i => i.farmId}/>
                    </div>
                },
                {
                    key: 'fields',
                    text: context.word('field_and_blocks'),
                    content: () => <SetupLandFields cropType={cropType} lands={lands} next={(cropType, data) => {
                        setLands(data)
                        setCropType(cropType)
                        stepperRef.current?.next()
                    }
                    }/>
                },
                {
                    key: 'template',
                    text: context.word('template_and_playfield'),
                    content: () => <div className="p-2">
                        <div className="text-xl underline tracking-wider">4. {context.word('template_and_playfield')}</div>
                        <TemplateStep next={data => generateProgram(data)}/>
                    </div>
                }
            ]}/>
    </div>
}

const TemplateStep: React.FC<{
    next: (playField: JsonPlayField) => void;
}> = (props) => {
    const context = useContext(AppContext)
    const [playFieldState, playFieldDispatch] = usePlayFieldState(emptyPlayFieldState)
    const [template, setTemplate] = useState<number | null>(null)
    const playFieldProducts = usePlayFieldProduct(playFieldDispatch)

    function templateChanged (value: number | null, item: PlayFieldTemplateResponse | null) {
        setTemplate(value)
        if (item === null) {
            playFieldDispatch({
                type: 'set',
                state: {
                    system: context.initial.system,
                    columns: [],
                    rows: [],
                    cells: [],
                    convertSize: emptyConvertSize
                }
            })
            return
        }

        playFieldProducts.setProducts(item.products)
        playFieldDispatch({
            type: 'set',
            state: buildPlayFieldState(item.playField.data, emptyConvertSize, context.initial.system, item.products)
        })
    }

    return <div>
        {context.word('template_and_playfield')}.

        <ChooseTemplate filterByRegions={true} value={template} onChange={templateChanged}/>

        <PlayfieldTemplate state={[playFieldState, playFieldDispatch]} 
                           products={playFieldProducts.products} 
                           addProduct={playFieldProducts.addProduct} 
                           editProduct={playFieldProducts.editProduct}
                           render={{
                               viewBlock: () => <></>,
                               totals: () => <></>,
                               extractions: (rowIndex) => <ElementValues system={context.initial.system} values={getExtraction(playFieldState, rowIndex)} emptyColor="text-white"/>
                           }}
                           tableGrapes={false}
        />

        <div className="text-right">
            <div className="btn bg-primary" onClick={() => props.next(stateToPlayField(playFieldState))}>{context.word('generate')}</div>
        </div>
    </div>
}

export default NewProgram
